import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import _  from "lodash";

import { withAuthorization } from '../Session';
import Layout from '../Page';
import {getProfiles, deleteProfile} from '../Utils/profiles';
import {getCollections} from '../Utils/collections';
import FilterDropdown from '../Utils/filterDropdown';
import ProfileModal from './profileModal';
import * as ROUTES from '../../constants/routes';

const headCells = [
  { id: 'name', numeric: false, align: 'left', disablePadding: false, label: 'NAME', width: '400px' },
  { id: 'collection', numeric: false, align: 'left', disablePadding: false, label: 'COLLECTION', width: 'auto' },
  { id: 'edit', numeric: true, align: 'left', disablePadding: false, label: '', width: '120px'  },
  { id: 'delete', numeric: true, align: 'left', disablePadding: false, label: '', width: '100px' },  
];

function EnhancedTableHead(props) {
  const { classes } = props;

  return (
    <TableHead>
      <TableRow className={classes.headRow}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'default'}             
            style={{width: headCell.width}}   
            className={classes.headCell}    
          >
            <Typography style={{fontSize: '14px', fontWeight: '500', letterSpacing: '1.5px'}}>
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    padding: '9px 32px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#ffffff',
    textTransform: 'initial',
    marginRight: '16px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableCell: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: '#000000'
  },
  headCell: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: '#000000'
  },
  headRow: {
    background: '#d9dfe9'
  },
  tableCellError: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: 'red'
  },
  tableRow: {
    backgroundColor: 'rgba(242, 244, 246, 0.4)'
  },  
  tableRow2: {
    backgroundColor: '#FFFFFF'
  },
  tableRowError: {
    backgroundColor: 'rgba(255, 0, 0, 0.1)'
  },
  selectedTableRow: {
    backgroundColor: '#F1F7FE!important',
  },
  pagination: {
    marginTop: '60px',
    '& ul': {
      justifyContent: 'center'
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  pageText: {
    color: '#000000',
    letterSpacing: '1.5px',
    fontSize: '14px',
    fontWeight: '500'
  },
  disabledButton: {
    backgroundColor: '#ccc!important',
    color: 'gray !important'
  },
  emptyText: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000'
  },
  inputQty: {
    '& input': {
      borderRadius: '6px',
      border: '1px solid rgba(0,0,0,0.08)',
      padding: '10px 12px'
    }
  },
  pageHeading: {
    
  },
  sectionHeading: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    marginBottom: '15px'
  },
  searchText: {
    fontSize: '14px',
    fontWeight: '500',
    color: 'rgba(0,0,0, 0.56)',
    textTransform: 'uppercase'
  },
  updateButton: {
    color: '#FFFFFF',
    textTransform: 'initial',
    width: '110px'
  },
}));


function Profiles(props) {  
  const [selected, setSelected] = useState("");
  const [loadProfile, setLoadProfile] = useState(true);
  const [loadCollection, setLoadCollection] = useState(true);
  const [profiles, setProfiles] = useState([]);   
  const [collections, setCollections] = useState([]);  
  const [openModal, setOpenModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [filterCollection, setFilterCollection] = useState('');
  const db = props.firebase.getdb();

  useEffect(() => {
    getProfiles(db, fetchProfileData);
    getCollections(db, fetchCollectionData);
  }, []);

  const fetchProfileData = (items) => {
    console.log('Fetch Data');
    setProfiles([...items]);
    setLoadProfile(false);
  };

  const fetchCollectionData = (items) => {
    console.log(items);

    const _newItems = _.map(items, (item, index) => {
      return {
        id: item.id,
        name: item.name
      };
    });
    _newItems.unshift({id: '', name:'All'});
    setCollections([..._newItems]);
    setLoadCollection(false);
  };

  const getCollectionName = (collectionId) => {
    const collection = _.find(collections, item => item.id===collectionId)?_.find(collections, item => item.id===collectionId).name:'';
    return collection;
  }

  const handleCloseDialog = () => {
    setOpenModal(false);
  }

  const handleCloseCreateDialog = () => {
    setOpenCreateModal(false);
  }

  const handleClick = (event, name) => {    
    setSelected(name);
  };

  const handelEditProfile = (event, name) => {
    setSelected(name);
    setOpenModal(true);
  }

  const isSelected = (name) => selected === name;

  const handleAddProfile = () => {
    setOpenCreateModal(true);
  }

  const handleCloseRemoveDialog = () => {
    setOpenDeleteModal(false);
  }

  const handleDeleteProfile = (event) => {
    const _selected = _.find(profiles, row => isSelected(row.name));  
    setOpenDeleteModal(false);
    setSelected("");
    deleteProfile(db, _selected);    
  }

  const renderRemoveDialog = () => {
    return (
      <Dialog onClose={handleCloseRemoveDialog} aria-labelledby="revision-dialog-title" open={openDeleteModal}>
        <DialogTitle id="revision-dialog-title" onClose={handleCloseRemoveDialog}>
          Confirmation
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Do you want to remove <strong>{selected}</strong> ?
          </Typography>          
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseRemoveDialog} color="primary">
            No
          </Button>
          <Button onClick={handleDeleteProfile} color="primary">Yes</Button>
        </DialogActions>
      </Dialog>
    );
  }

  const handleManageCollection = () => {
    window.location.href = ROUTES.COLLECTIONS;
  }

  const classes = useStyles();

  const selectedProfile = _.find(profiles, row => isSelected(row.name));  
  const filterProfiles = _.filter(profiles, profile => {
    if (filterCollection!=="")
      return filterCollection===profile.collection;
    return true;
  });
  return (
    <Layout page="profiles" >
      <div className="pageHeader">
        <div className={classes.pageHeading}>
          <Typography variant="h1">Profiles</Typography> 
          <Typography className={classes.searchText}><strong>{profiles.length}</strong> Total Profiles</Typography>   
        </div>   
        <div className={classes.buttonBox}>
          {
            /*
            <Button
              variant="contained"
              className={classes.button}
              onClick={handleManageCollection}
              style={{background: '#3d3d3d'}}          
            >Manage Collections</Button>
            */
          }

        </div>
      </div>

      <div className='pageToolbar'>
        <div className={classes.buttonBox}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={handleAddProfile}
            style={{background: '#1f7cf2'}}          
          >
            Add Profile
          </Button>
        </div>

        {collections.length > 0 && (
        <div className={classes.buttonBox}>
          <FilterDropdown title="COLLECTION" items={collections} active={filterCollection} onSelect={setFilterCollection} />
        </div>
        )}
 
      </div>

      <TableContainer style={{marginTop: '30px', marginBottom: '50px'}}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"            
        >
          <EnhancedTableHead classes={classes} />
          <TableBody>
            {filterProfiles
              .map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;
                const keyId = `key-${row.sku}-${index}`;
                const odd = index%2 === 0;
                return (
                  <TableRow
                    hover                                        
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={keyId}                      
                    classes={{
                      root: odd?classes.tableRow:classes.tableRow2,
                      selected: classes.selectedTableRow, 
                    }}
                    selected={isItemSelected} 
                    onClick={(event) => handleClick(event, row.name)}                       
                  >
                    <TableCell align="left" className={classes.tableCell}>{row.name}</TableCell>
                    <TableCell align="left" className={classes.tableCell}>{loadProfile===false && loadCollection===false && getCollectionName(row.collection)}</TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <Button
                        variant="contained"
                        className={classes.updateButton}
                        style={{background: '#1F7CF2'}} 
                        onClick={() => handelEditProfile(row.name)}         
                      >
                        Edit
                      </Button>
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      <Button onClick={() => setOpenDeleteModal(true)}>
                        <DeleteOutlineIcon />
                      </Button>

                    </TableCell>
                  </TableRow>                  
                );
              })}

              {loadProfile && loadCollection && (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    <CircularProgress />  
                  </TableCell>
                </TableRow>
              )}

              {loadProfile === false && loadCollection === false && profiles.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={4} className={classes.tableCell}>
                    No Profiles
                  </TableCell>

                </TableRow>
              )}
            
          </TableBody>
        </Table>
      </TableContainer> 
      
      {selected && (<ProfileModal open={openModal} handleClose={handleCloseDialog} item={selectedProfile} modal_title={selectedProfile && selectedProfile.name} />)}    

      <ProfileModal isCreate open={openCreateModal} handleClose={handleCloseCreateDialog} item={null} modal_title="New Profile"/>  

      {renderRemoveDialog()} 
    </Layout>
  );
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(Profiles);

