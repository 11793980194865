import React, { useState, useEffect, useRef } from "react"
import { GridContextProvider, GridDropZone, GridItem, swap } from "react-grid-dnd-17"

import CloseIcon from "@material-ui/icons/Close"
// import DragIndicatorIcon from "@material-ui/icons/DragIndicator"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline"

import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import InputBase from "@material-ui/core/InputBase"
import InputLabel from "@material-ui/core/InputLabel"
import Input from "@material-ui/core/Input"
import Popover from "@material-ui/core/Popover"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { Divider } from "@material-ui/core"
import { ColorInput, ColorBox, ColorButton } from "material-ui-color"

import shortid from "shortid"

import { createColorSet, updateColorSet } from "../Utils/colors"
import { DEFAULT_COLORSET } from "../Utils/defines"
import { withAuthorization } from "../Session"

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: "15px",
      width: "100%",
    },
    label: {
      fontSize: "16px",
      fontWeight: "500",
      color: "#000000",
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 10px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase)

const useHeaderStyles = makeStyles(theme => ({
  closeButton: {
    position: "absolute",
    right: "10px",
    top: "10px",
    color: "#000000",
  },
  plusButton: {},
  inputRoot: {
    width: "100%",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "1.5px",
    color: "#000000",
    background: "rgba(0,0,0,0.04)",
    padding: "11px 15px",
    borderRadius: "4px",
  },
  inputLabel: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#000000",
  },
  addButton: {
    background: "#3D3D3D",
    padding: "8px 32px",
    marginRight: "15px",
    borderRadius: "6px",
    fontSize: "16px",
    fontWeight: "500",
    color: "#FFFFFF",
    textTransform: "initial",
  },
  blueButton: {
    background: "#1F7CF2",
    padding: "8px 56px",
    borderRadius: "6px",
    fontSize: "16px",
    fontWeight: "500",
    color: "#FFFFFF",
    textTransform: "initial",
  },
  colorsWrap: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px",
  },
  attrHeading: {
    fontSize: "22px",
    fontWeight: "500",
    color: "#000000",
  },
  labelBox: {
    width: "calc(100% - 80px)",
    paddingLeft: "12px",
    "& label": {
      display: "none",
    },
    "& input": {
      height: "30px",
    },
  },
  dropzone: {
    flex: 1,
    height: "400px",
    width: "100%",
    maxWidth: "720px",
    // overflowX: "hidden",
  },
  colorGridItem: {
    display: "flex",
    cursor: "default !important",
  },
  colorBoxWrap: {
    alignItems: "center",
  },
  // colorDragIcon: {
  //   cursor: "grab",
  //   transition: "all 0.25s ease-out",
  //   "&:hover": {
  //     color: "#1F7CF2",
  //     transform: "scale(1.5)",
  //   },
  // },
  // colorBoxButton: {
  //   boxShadow: "0 4px 6px rgb(255, 0, 0)",
  // },
}))

const columns = 12

/* Color Item */
function ColorItem(props) {
  const { classes, label, index, onChange, onDelete, onOpen } = props
  const [color, setColor] = useState(props.color)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const dragIconRef = useRef()
  const allowClick = useRef(true)

  const handleMouseEvents = e => {
    if (dragIconRef.current && dragIconRef.current.contains(e.target)) {
      switch (e.type) {
        case "mousedown":
          allowClick.current = true
          window.addEventListener("mousemove", handleMouseEvents)
          onOpen(false)
          break
        case "mousemove":
          allowClick.current = false
          break
        default:
          window.removeEventListener("mousemove", handleMouseEvents)
          onOpen(true)
          break
      }
    } else {
      allowClick.current = true
    }
  }

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseEvents)
    window.addEventListener("mouseup", handleMouseEvents)

    return () => {
      window.removeEventListener("mousedown", handleMouseEvents)
      window.removeEventListener("mouseup", handleMouseEvents)
      window.removeEventListener("mousemove", handleMouseEvents)
    }
  }, [])

  useEffect(() => {
    if (onChange) {
      onChange(index, color)
    }
  }, [color])

  const handleClick = event => {
    if (allowClick.current) setAnchorEl(event.currentTarget)
    allowClick.current = true
  }

  const handleClose = () => {
    onOpen(false)
    setAnchorEl(null)
  }

  const handleChangeColor = value => {
    if (value == undefined) return

    console.log("New Color: ", value)
    setColor(`#${value.hex}`)
  }

  const handleDelete = event => {
    if (onDelete) onDelete(index)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  return (
    <Box display="flex" className={classes.colorBoxWrap} ref={dragIconRef} id="colorBox">
      {/* <DragIndicatorIcon className={classes.colorDragIcon} ref={dragIconRef} /> */}
      <Box className={classes.colorBox} onClick={handleClick}>
        {color ? (
          <ColorButton color={color} size={40} className={classes.colorBoxButton} />
        ) : (
          <Box className={classes.noColorBox}></Box>
        )}
      </Box>
      {/* <Box className={classes.labelBox}>
        <Input
          placeholder="Color"
          defaultValue={color ? color : "#"}
          value={color ? color : "#"}
          onChange={e => setColor(`#${e.target.value.replace(/#/gi, "")}`)}
          style={{ width: "fit-content" }}
        />
      </Box> */}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box>
          <ColorBox defaultValue={color} onChange={handleChangeColor} />
          <Box>
            <Button onClick={handleDelete}>
              <DeleteOutlineIcon />
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}

function ColorSetModal(props) {
  const { handleClose, open, isCreate, dataCallback } = props
  const [error, setError] = useState({ message: "", label_red: false })
  const [colorSet, setColorSet] = useState({ ...DEFAULT_COLORSET })
  const db = props.firebase.getdb()
  const storage = props.firebase.getstorage()

  useEffect(() => {
    if (open) {
      if (isCreate) {
        setColorSet({ ...DEFAULT_COLORSET })
      } else {
        setColorSet({ ...props.item })
      }
    }
  }, [open])

  const handleSave = () => {
    if (!colorSet.name) {
      setError({ ...error, label_red: true, message: "Name is required!" })
    } else {
      const colorMethod = isCreate ? createColorSet : updateColorSet
      colorMethod(db, colorSet, dataCallback)
      setError({ ...error, label_red: false, message: "" })
      handleClose()
    }
  }

  const handleCloseDialog = (event, reason) => {
    if (reason === "backdropClick") {
      return
    }
    handleClose()
  }

  const handleChange = event => {
    let { name, value } = event.target
    let _colorSet = { ...colorSet }
    _colorSet[name] = value

    setColorSet(_colorSet)
  }

  const handleAddColor = () => {
    let items = [...colorSet.items]
    const id = shortid.generate()
    let item = { id, value: "#FFFFFF" }
    items.push(item)
    setColorSet({ ...colorSet, items })
  }

  const handleChangeColor = (id, color) => {
    const items = _.map(colorSet.items, item => {
      if (item.id === id) return { ...item, value: color }
      return { ...item }
    })
    setColorSet({ ...colorSet, items })
  }

  const handleDeleteColor = id => {
    const items = _.filter(colorSet.items, item => {
      return item.id !== id
    })
    setColorSet({ ...colorSet, items })
  }

  const classes = useHeaderStyles()
  const scroll = "paper"

  const handleColorDragDrop = (sourceId, sourceIndex, targetIndex, targetId) => {
    setColorSet(prevColorSet => ({
      ...prevColorSet,
      items: swap([...prevColorSet.items], sourceIndex, targetIndex),
    }))
  }

  const [disableDrag, setDisableDrag] = useState(true)

  return (
    <Dialog
      onClose={handleCloseDialog}
      aria-labelledby="colorset-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"lg"}
      scroll={scroll}
    >
      <DialogTitle onClose={handleCloseDialog} style={{ padding: "40px 40px 30px", position: "relative" }}>
        <Typography variant="h1">{isCreate ? "New Color Set" : "Update Color Set"}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "0 40px" }} dividers>
        <Box style={{ padding: "30px 0" }}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <InputLabel className={classes.inputLabel}>Color Set Name</InputLabel>
              <BootstrapInput name="name" value={colorSet.name} onChange={handleChange} />
            </Grid>
          </Grid>
        </Box>

        {colorSet && (
          <Box style={{ padding: "20px 0" }}>
            <Typography className={classes.attrHeading}>Color Set Attributes</Typography>

            <GridContextProvider onChange={handleColorDragDrop}>
              <div className="container">
                <GridDropZone
                  className={classes.dropzone}
                  id="left"
                  boxesPerRow={columns}
                  rowHeight={60}
                  disableDrag={disableDrag}
                >
                  {colorSet.items.map(({ id: itemId, value }) => (
                    <GridItem key={itemId} className={classes.colorGridItem}>
                      <ColorItem
                        key={`coloritem_${itemId}`}
                        index={itemId}
                        classes={classes}
                        color={value}
                        onChange={handleChangeColor}
                        onDelete={handleDeleteColor}
                        onOpen={setDisableDrag}
                      />
                    </GridItem>
                  ))}
                  <IconButton
                    aria-label="close"
                    className={classes.plusButton}
                    onClick={e => handleAddColor()}
                    style={{
                      transform: `translate3d(${60 * (colorSet.items.length % columns) - 4}px, ${
                        60 * Math.floor(colorSet.items.length / columns) + 6
                      }px, 0px) scale(1)`,
                    }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </GridDropZone>
              </div>
            </GridContextProvider>
          </Box>
        )}
      </DialogContent>

      <DialogActions style={{ padding: "40px", justifyContent: "flex-start" }}>
        <Button variant="contained" color="primary" className={classes.blueButton} onClick={handleSave}>
          Save
        </Button>
        {error.label_red && error.message && <p className="error-message">{error.message}</p>}
      </DialogActions>
    </Dialog>
  )
}

const condition = authUser => !!authUser
export default withAuthorization(condition)(ColorSetModal)
