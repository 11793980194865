import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {getProfiles} from '../Utils/profiles';
import { withAuthorization } from '../Session';
import Layout from '../Page';
import {getDesigns, deleteDesign} from '../Utils/designs';
import {getCollections} from '../Utils/collections';
import {getColorSets} from '../Utils/colors';
import DesignModal from './designModal';
import {deleteDesignLayers} from '../Utils/layers';
import { getNeutrals } from '../Utils/neutrals';
import FilterDropdown from '../Utils/filterDropdown';
import DesignGridItem from './designGridItem';

const headCells = [
  { id: 'name', numeric: false, align: 'left', disablePadding: false, label: 'NAME', width: 'auto' },
  { id: 'edit', numeric: true, align: 'left', disablePadding: false, label: '', width: '120px'  },
  { id: 'delete', numeric: true, align: 'left', disablePadding: false, label: '', width: '100px' },  
];

function EnhancedTableHead(props) {
  const { classes } = props;

  return (
    <TableHead>
      <TableRow className={classes.headRow}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'default'}             
            style={{width: headCell.width}}   
            className={classes.headCell}    
          >
            <Typography style={{fontSize: '14px', fontWeight: '500', letterSpacing: '1.5px'}}>
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    padding: '9px 32px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#ffffff',
    textTransform: 'initial',
    marginRight: '16px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableCell: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: '#000000'
  },
  headCell: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: '#000000'
  },
  headRow: {
    background: '#d9dfe9'
  },
  tableCellError: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: 'red'
  },
  tableRow: {
    backgroundColor: 'rgba(242, 244, 246, 0.4)'
  },  
  tableRow2: {
    backgroundColor: '#FFFFFF'
  },
  tableRowError: {
    backgroundColor: 'rgba(255, 0, 0, 0.1)'
  },
  selectedTableRow: {
    backgroundColor: '#F1F7FE!important',
  },
  pagination: {
    marginTop: '60px',
    '& ul': {
      justifyContent: 'center'
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  pageText: {
    color: '#000000',
    letterSpacing: '1.5px',
    fontSize: '14px',
    fontWeight: '500'
  },
  disabledButton: {
    backgroundColor: '#ccc!important',
    color: 'gray !important'
  },
  emptyText: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000'
  },
  inputQty: {
    '& input': {
      borderRadius: '6px',
      border: '1px solid rgba(0,0,0,0.08)',
      padding: '10px 12px'
    }
  },
  pageHeading: {
    
  },
  sectionHeading: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    marginBottom: '15px'
  },
  searchText: {
    fontSize: '14px',
    fontWeight: '500',
    color: 'rgba(0,0,0, 0.56)',
    textTransform: 'uppercase'
  },
  updateButton: {
    color: '#FFFFFF',
    textTransform: 'initial',
    width: '110px'
  },
}));

const filterFormatOptions = [
  {id: "", name: "All"},
  {id: "Crew", name: "Crew"},
  {id: "Low", name: "Low"}
];

function Designs(props) {  
  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(true);
  const [designs, setDesigns] = useState([]);  

  const [filterFormat, setFilterFormat] = useState('');
  const [loadProfile, setLoadProfile] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [profiles, setProfiles] = useState([]);  
  const [colorSets, setColorSets] = useState([]);
  const [neutrals, setNeutrals] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const db = props.firebase.getdb();

  useEffect(() => {
    getDesigns(db,  "normal", fetchData);
    getColorSets(db, fetchColorSets);
    getNeutrals(db, fetchNeutrals);
    
  }, []);

  const fetchProfileData = (items) => {
    console.log('Fetch Data');
    setProfiles([...items]);
    setLoadProfile(false);
  };

  const fetchColorSets = (items) => {
    console.log('Fetch ColorSets Data');
    setColorSets([...items]);
  }

  const fetchNeutrals = (items) => {
    setNeutrals([...items]);
  }

  const fetchData = (items) => {
    console.log('Fetch Design Data');
    setDesigns([...items]);
    setLoading(false);
  };

  const handleCloseDialog = () => {
    setOpenModal(false);
  }

  const handleCloseCreateDialog = () => {
    setOpenCreateModal(false);
  }

  const handleClick = (item) => {    
    console.log('Design Clicked! ', item.id);
    setSelected(item.id);
    setOpenModal(true);
  };

  const handleAddTemplate = () => {
    setOpenCreateModal(true);
  }

  const handleCloseRemoveDialog = () => {
    setOpenDeleteModal(false);
  }

  const handleDeleteDesign = (event) => {
    const _selected = _.find(designs, row => isSelected(row.id));  
    setOpenDeleteModal(false);
    setSelected("");
    deleteDesign(db, _selected); 
    //deleteDesignLayers(db, _selected);

  }

  const renderRemoveDialog = () => {
    return (
      <Dialog onClose={handleCloseRemoveDialog} aria-labelledby="revision-dialog-title" open={openDeleteModal}>
        <DialogTitle id="revision-dialog-title" onClose={handleCloseRemoveDialog}>
          Confirmation
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Do you want to remove <strong>{selected}</strong> ?
          </Typography>          
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseRemoveDialog} color="primary">
            No
          </Button>
          <Button onClick={handleDeleteDesign} color="primary">Yes</Button>
        </DialogActions>
      </Dialog>
    );
  }

  const classes = useStyles();
  const isSelected = (name) => selected === name;
  const selectedTemplate = _.find(designs, row => isSelected(row.id));  
  let _designs;
  if (filterFormat === "")
    _designs = [...designs];
  else 
    _designs = _.filter(designs, {format:filterFormat});
  return (
    <Layout page="designs" >
      <div className="pageHeader">
        <div className={classes.pageHeading}>
          <Typography variant="h1">Designs</Typography> 
          <Typography className={classes.searchText}><strong>{designs.length}</strong> Total Designs</Typography>   
        </div>             
      </div>

      <div className='pageToolbar'>
        <div className={classes.buttonBox}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={handleAddTemplate}
            style={{background: '#1f7cf2'}}          
          >
            Add Design
          </Button>
        </div>

        <Box display="flex">
          <div className={classes.buttonBox}>
            <FilterDropdown title="Format" items={filterFormatOptions} active={filterFormat} onSelect={setFilterFormat} />
          </div>
        </Box>
      </div>

      <Box style={{paddingTop:'20px'}}>
        <Grid container spacing={3}>
          {_designs.map((row, i) => {
            return (
              <Grid item xs={3}>
                <DesignGridItem 
                  key={`Design_${row.sku}`}
                  firebase={props.firebase.getFirebase()} 
                  data={row}
                  label="Edit"
                  handleClick={handleClick}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      
      {selected && (
      <DesignModal 
        key="edit_design_modal" 
        profiles={profiles} 
        colorSets={colorSets} 
        neutrals={neutrals}
        modal_title="Edit" 
        open={openModal} 
        handleClose={handleCloseDialog} 
        item={selectedTemplate} 
      />)}    

      <DesignModal 
        key="create_design_modal" 
        profiles={profiles} 
        colorSets={colorSets} 
        neutrals={neutrals}
        modal_title="Add" 
        isCreate 
        open={openCreateModal} 
        handleClose={handleCloseCreateDialog} 
        item={null} 
      />  

      {renderRemoveDialog()}
    </Layout>
  );
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(Designs);

