import React, { useState, useEffect } from 'react';
import { makeStyles  } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import _  from "lodash";
import { formatDate, formatTime } from '../Utils/functions';
import { StoragePath } from '../Utils/defines';
import { BucketImg } from '../Designs/artComponents';


const useStyles = makeStyles((theme) => ({
    itemBox: {
        border: '2px solid #EEEEEE',
        background: '#FFFFFF',
        borderRadius: '12px'
    },
    textName: {
        marginTop: '40px',
        fontWeight: '500',
        color: '#000000',        
        fontSize: '17px',
        lineHeight: 1.3
    },
    textSKU: {
        paddingTop: '2px',
        fontWeight: '500',        
        color: 'rgba(0, 0, 0, 0.56)',
        fontSize: '15px',
        lineHeight: 1.2
    },
    textInfo: {
        marginTop: '30px',
        fontWeight: '400',
        color: '#1f7cf2',        
        fontSize: '15px',
        lineHeight: 1.2
    },
    fullImg: {
        width: '100%',
        height: 'auto'
    },
    sizeText: {
        fontSize: '18px',
        lineHeight: '24px',
        color: '#000000',
        textAlign: 'center'
    },
    button: {
        width: '80px',
        background: '#1f7cf2',
        color: '#FFFFFF',
        textTransform: 'initial'
    },
    infoBox: {
        position: 'relative',
        height: '100%',
        padding: '0px 0px'
    },
    buttonBox: {
        position: 'absolute',
        right: '30px',
        bottom: '30px'
    },
    textBox: {
        paddingTop: '20px'
    },
    overlayBox: {
        position: 'relative',
        paddingTop: '100%'
    },
    loadingIcon: {
        position: 'absolute',
        top: '40%',
        left: '40%'
    }
}));

// primary row component
function ProductGridItem(props) {
    const { data, firebase, label, handleClick } = props;
    const [loading, setLoading] = useState(!data.shopifyCreated);
    const classes = useStyles();

    useEffect(() => {
        console.log(`Shopify Product:${props.data.shopifyProduct}, Created:${props.data.shopifyCreated}`);
        setLoading(!props.data.shopifyCreated);
    }, [props.data]);

    const renderArt = () => {        
        let path = `${StoragePath.products}/${data.id}.png`;
        return (
            <Box style={{padding: '20px 10px'}}>
                <BucketImg 
                    loading={!data.shopifyCreated}
                    firebase={firebase} 
                    path={path}
                />
            </Box>
        );
    }

    const renderLoading = () => {
        return (
            <Box style={{padding: '20px 10px'}} >
                <Box className={classes.overlayBox}>
                    <Box className={classes.loadingIcon}>
                        <CircularProgress />
                    </Box>                    
                </Box>
            </Box>
        );
    }

    const handleClickItem = (event) => {
        if (handleClick)
            handleClick(data.id);        
    }

    const modifyDate = `${formatDate(data.modifiedOn)} ${formatTime(data.modifiedOn)}`;

    return (
        <Box className={classes.itemBox}>
            <Grid container>
                <Grid item xs={6}>{loading?renderLoading():renderArt()}</Grid>
                <Grid item xs={6}>
                    <Box className={classes.infoBox}>
                        <Box className={classes.textBox}>
                            <Typography className={classes.textName}>{data.name}</Typography>
                            <Typography className={classes.textSKU}>{`${data.sku}-LXL`}</Typography>     
                        </Box>

                        <Box className={classes.subBox4}>
                            <Typography className={classes.textInfo}>Last Update: {modifyDate}</Typography>                                    
                        </Box>

                        <Box className={classes.buttonBox}>
                            <Button
                                variant="contained"
                                className={classes.button}
                                onClick={handleClickItem}
                            >{label}</Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ProductGridItem;
