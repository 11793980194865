import { collections } from "./defines"
import _ from "lodash"
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore"

const getColorSets = (db, callback) => {
  const q = query(collection(db, collections.COLORSETS), orderBy("name"))

  return getDocs(q).then(querySnapshot => {
    let items = []
    querySnapshot.forEach(doc => {
      items.push({ ...doc.data(), id: doc.id })
    })
    callback(items)
  })
}

const deleteColorSet = (db, item, callback) => {
  return new Promise((resolve, reject) => {
    deleteDoc(doc(db, collections.COLORSETS, item.id)).then(() => {
      callback && callback(item, "delete")
      resolve()
    })
  })
}

const createColorSet = (db, item, callback) => {
  return new Promise((resolve, reject) => {
    const newItem = { ...item }
    newItem.created = new Date()

    addDoc(collection(db, collections.COLORSETS), newItem)
      .then(docRef => {
        resolve(docRef.id)
        callback && callback(item, "create")
      })
      .catch(error => {
        console.error("Error adding document: ", error)
      })
  })
}

const updateColorSet = (db, item, callback) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, collections.COLORSETS, item.id)
    updateDoc(docRef, { ...item })
      .then(() => {
        console.log("Document successfully updated!")
        resolve()
        callback && callback(item, "update")
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error)
        reject()
      })
  })
}

export { getColorSets, deleteColorSet, createColorSet, updateColorSet }
