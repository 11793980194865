import React, { useState, useEffect, createRef } from 'react';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import {updateLayer} from '../Utils/layers';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dropzone from 'react-dropzone';
import { withAuthorization } from '../Session';
import {DEFAULT_LAYER, NEUTRAL_OPTIONS, LAYER_OPTIONS, LAYER_CLASS_OPTIONS, DEFAULT_LAYER_POS, StoragePath} from '../Utils/defines';
import {existFile} from '../Utils/functions';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: '10px',
        width: '100%'
      },
      'label': {
        fontSize: '16px',
        fontWeight: '500',
        color: '#000000'
      }
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'rgba(0,0,0,0.04)',
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 10px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  
  }))(InputBase);

const useHeaderStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    color: '#000000',
  },
  inputRoot: {
    width: '100%',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px',
    color: '#000000',
    background: 'rgba(0,0,0,0.04)',
    padding: '11px 15px',
    borderRadius: '4px'
  },
  inputLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000'
  },
  addButton: {
    background: '#3D3D3D',
    padding: '8px 32px',
    marginRight: '15px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial'
  },
  blueButton: {
    background: '#1F7CF2',
    padding: '8px 56px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial'
  },
  cancelButton: {
    padding: '8px 56px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#000',
    textDecoration: 'underline',
    textTransform: 'initial'
  },
  modal_title: {
    fontSize: '32px', 
    margin: 0, 
    fontWeight: '500'
  },
  modal_subtitle: {
    fontSize: '22px', 
    margin: 0, 
    fontWeight: '500',
    marginBottom: '30px'  
  },
  dropBox: {    
    textAlign: 'center',
    padding: '88px 0px',
    borderRadius: '6px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    marginBottom: '25px',
    '& svg' : {
      opacity: '0.56'
    }
  },
  dropLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    opacity: '0.56',
    margin: '0'
  },
  dropButton: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    width: '100%',
    background: '#3D3D3D',
    borderRadius: '6px',
    textTransform: 'Capitalize',
    height: '40px',
    padding: '0'
  },
  imageBox: {
    position: 'relative',
    height: '320px',
    border: 'solid 1px rgba(217, 223, 233, 0.4)',
  },
  coverImage: {
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'top center',
    textAlign: 'center'
  },
  hImage: {
    height: 'auto',
    width: '100%'
  },
  vImage: {
    height: '100%',
    width: 'auto',
    margin: '0 auto'
  },
}));

function ItemImage(props) {
    const {firebase, path, progress, classes, name} = props;
    const [imagePath, setImagePath] = useState("");
    const [width, setWidth] = useState(320);
    const [height, setHeight] = useState(320);
    const [load, setLoad] = useState(true);
  
    
    useEffect(() => {
      if (path === "") {
        setLoad(false);
      } else {
        const storage = firebase.getstorage();
        getDownloadURL(ref(storage, `${StoragePath.images}/${path}`))
        .then(url => {
          setImagePath(url);
          setLoad(true);
          var img = new Image();
          img.onload = function(){
            setWidth(this.width);
            setHeight(this.height);
            setLoad(false);
          };
          img.src = url;
        })
        .catch(error => {
          console.log(error);
        });
      }
    }, [props.name, props.path]);
  
    const ratio = width?width/height: 1;
  
    return (
      <Box className={classes.imageBox}>
        {progress < 100 && (<LinearProgress variant="determinate" value={progress} color="primary" style={{width: '100%'}} />)}
        <Box className={classes.coverImage}>
        {
          progress >= 100 && load?
            <CircularProgress /> :
            <img key={name} src={imagePath}  className={ratio>0.9? classes.hImage: classes.vImage}/>
        }
        </Box>
      </Box>
    );
}

const dropzoneRef1 = createRef();

function LayerModal(props) {
  const {handleClose, open, isCreate, handleAdd, handleUpdate, handleDelete} = props;
  const [layer, setLayer] = useState({...DEFAULT_LAYER});
  const [locked, setLocked] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(100);

  const db = props.firebase.getdb();
  const storage = props.firebase.getstorage();

  useEffect(() => {
    if (open) {
      if (isCreate) {
        const _layer = {...DEFAULT_LAYER};                
        _layer.template = props.template;
        setLayer({..._layer});
      } else {
        setLayer({...props.layer, pos:{...props.layer.pos}});
      }        
    }
  }, [open]);

  const handleSave = () => {
    if (isCreate) {
      const docId = Date.now();
      if (handleAdd)
        handleAdd({...layer, id: docId.toString()});
    } else {
      if (handleUpdate)
        handleUpdate(layer);      
    }
    handleClose();
  }

  const handleCloseDialog = (event) => {
    handleClose(); 
  }

  const handleLocked = () => {
    const _layer = {...layer};
    if (layer.pos.width <= 0 || layer.pos.height<= 0)
      return;
    setLocked(!locked);
  }

  const handleChangePosRatio = (event) => {
    let { name, value } = event.target;
    value = parseFloat(value);
    const _layer = {...layer};
    const {width, height, _width, _height} = _layer.pos;
    let newH, newW;
    if (name === 'width') {
      newW = value;
      newH = (_height/_width) * value;
    } else {
      newH = value;
      newW = (_width/_height) * value;
    }
    _layer.pos.width = parseInt(newW);
    _layer.pos.height = parseInt(newH);
    setLayer(_layer);
  }

  const handleChangePos = (event) => {
    let { name, value } = event.target;
    value = parseFloat(value);

    // Handle face slots if num changed
    const _layer = {...layer};
    _layer.pos[name] = value;
    if (name === "width") {
      _layer.pos["_width"] = value;
    } else if (name === "height") {
      _layer.pos["_height"] = value;
    }

    setLayer(_layer);
  }

  const renderProperties = () => {
    return (
        <Box>
            <Typography className={classes.modal_subtitle}>Layer Properties</Typography>
            <Grid container spacing={1}>
                <Grid item xs={5} >
                    <label className={classes.label} htmlFor="admin-label-helper">X Position</label>
                    <BootstrapInput 
                        type="number" 
                        name="x" 
                        value={layer.pos.x} 
                        onChange={handleChangePos}
                        inputProps={{min:0,step:1}}
                    />            
                </Grid>
                <Grid item xs={1} ></Grid>
                <Grid item xs={5} >
                    <label className={classes.label} htmlFor="admin-label-helper">Y Position</label>
                    <BootstrapInput 
                        type="number" 
                        name="y" 
                        value={layer.pos.y} 
                        onChange={handleChangePos} 
                        inputProps={{min:0,step:1}}
                    />          
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{marginTop: '10px'}}>
                <Grid item xs={5} >
                    <label className={classes.label} htmlFor="admin-label-helper">Width</label>
                    <BootstrapInput 
                      type="number" 
                      name="width"               
                      value={layer.pos.width} 
                      onChange={locked?handleChangePosRatio:handleChangePos} 
                      inputProps={{min:0,step:1}}
                      />            
                </Grid>
                <Grid item xs={1} >
                    <Box style={{marginTop:'35px'}} onClick={() => handleLocked()}>
                    {locked?(<LockIcon/>):(<LockOpenIcon/>)}              
                    </Box>           
                </Grid>
                <Grid item xs={5} >
                    <label className={classes.label} htmlFor="admin-label-helper">Height</label>
                    <BootstrapInput 
                      type="number" 
                      name="height" 
                      value={layer.pos.height} 
                      onChange={locked?handleChangePosRatio:handleChangePos} 
                      inputProps={{min:0, step:1}}
                      />          
                </Grid>            
            </Grid>
        </Box>
    );
  }

  const handleDropFile = (file) => {    
    existFile(storage, `${StoragePath.images}/${file.name}`)
      .then(exist => {
        console.log(`Image Art '${file.name}' exist:${exist}`);
        let filename = file.name;
        if (exist) {
          const _filename = filename.split('.').slice(0, -1).join('.');
          const ext = filename.substr(filename.lastIndexOf('.') + 1);
          filename = `${_filename}-${Date.now()}.${ext}`; 
        } 
        handleUploadImage(file, filename);
      }); 

  }

  const handleUploadImage = (file, filename) => {
    console.log("handleUploadImage");

    const fr = new FileReader();
    fr.onload = function () {
      const img = new Image();
      img.onload = function() {
        const width = img.width, height = img.height;
        const storageRef = ref(storage, `${StoragePath.images}/${filename}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        
        setUploadProgress(0); 
        uploadTask.on('state_changed', 
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                setUploadProgress(progress);
                break;
            }
          }, 
          (error) => {
            // Handle unsuccessful uploads
          }, 
          () => {
            // Upload completed successfully, now we can get the download URL
            setUploadProgress(100); 
            let _layer = {...layer};
            if (_layer.assetClass === 'logo' && _layer.assetType === 'object') {
              _layer.pos.x = 0;
              _layer.pos.y = 0;
              _layer.pos.width = 600;
              _layer.pos.height = 600;
              _layer.pos._width = 600;
              _layer.pos._height = 600;
            } else {
              _layer.pos.x = 0;
              _layer.pos.y = 0;
              _layer.pos.width = width;
              _layer.pos.height = height;
              _layer.pos._width = width;
              _layer.pos._height = height;
            }

            _layer.image = filename;
            setLayer(_layer);
          }
        );
      }
      img.src = fr.result;
    };
    fr.readAsDataURL(file);
  }

  const handleClickUploadLogo = () => {
    console.log('handleClickUploadBack');
    dropzoneRef1.current.open();
  }

  const renderLogo = () => {
    return (
    <Box style={{width: '100%'}} >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box style={{marginTop: '16px', width: '100%'}}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <ItemImage 
                    key="logoImage"
                    name="logoImage"
                    firebase={props.firebase.getFirebase()} 
                    classes={classes} 
                    progress={uploadProgress} 
                    path={layer.image} 
                  />
                </Grid>
                <Grid item xs={4}>
                  <Dropzone 
                    ref={dropzoneRef1} 
                    onDrop={(files) => handleDropFile(files[0])} 
                    multiple={false}>
                    {({getRootProps, getInputProps}) => (
                      <Box {...getRootProps()} className={classes.dropBox}>
                        <input {...getInputProps()} accept="image/*"/>
                        <CloudUploadOutlinedIcon />
                        <p className={classes.dropLabel}>Drag & Drop Artwork File</p>
                      </Box>
                    )}
                  </Dropzone>

                  <Button 
                    variant="contained" 
                    onClick={handleClickUploadLogo} 
                    className={classes.dropButton}>Upload</Button>
                </Grid>
              </Grid>              
            </Box>

          </Grid>
        </Grid>
    </Box>        
    );
  }

  const handleChange = event => {
      console.log('handleChange');
      let { name, value, checked, type} = event.target;   
      value = type === "checkbox" ? checked : value;

      let _layer = {...layer};
      _layer[name] = value;
      if (name === 'assetType' && value === 'object') {
          _layer.pos.width = 600;
          _layer.pos.height = 600;
          _layer.pos._width = 600;
          _layer.pos._height = 600;
      } else if (name === 'custom1' && (value === 'primary_neutral_color' || value === 'secondary_neutral_color' || value === 'primary_neutral_tonal' || value === 'secondary_neutral_tonal' || value === 'primary_neutral_tonal2' || value === 'secondary_neutral_tonal2')) {
        _layer.swatch = 'neutrals';
      }
      setLayer(_layer);
  }

  const handleDeleteLayer = () => {
    if (handleDelete)
      handleDelete(layer.id);
    handleClose();
  }

  const classes = useHeaderStyles();
  const scroll = 'paper';

  const layerClassOptions = _.filter(LAYER_CLASS_OPTIONS, {type: layer.assetType});
  const layerOptions = _.filter(LAYER_OPTIONS, {type: layer.assetType});
  
  return (
    <Dialog onClose={handleCloseDialog} 
        aria-labelledby="layer-dialog-title" 
        open={open} 
        fullWidth={true} 
        maxWidth={"sm"} 
        className="newlayer-modal"
        scroll={scroll}
    >
        <DialogTitle onClose={handleCloseDialog} style={{padding: '20px 40px 20px', position: 'relative'}}>
            <Typography className={classes.modal_title}>{isCreate?"Add New Layer":"Update Layer"}</Typography>              
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseDialog}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent style={{padding: '0 40px', borderTop: 'none', overflow: 'hidden'}} dividers>
            <Divider/>
            <Box style={{padding: '25px 0 0'}}>    
                <Grid container spacing={1}>            
                    <Grid item xs={12} sm={12}>
                        <label className={classes.modal_label} htmlFor="admin-label-helper">Layer Name</label>
                        <BootstrapInput 
                            type="text" 
                            name="name"
                            value={layer.name}
                            onChange={handleChange}
                        />  
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="static"
                            checked={layer.static}
                            onChange={handleChange}
                          />
                        }
                        label="Static Layer (No Color Overlay)"
                      />
                    </Grid>
                </Grid>

                <Grid container spacing={1} style={{padding: '10px 0 40px'}}>   
                    <Grid item xs={12} sm={12}>{renderLogo()}</Grid>
                </Grid>               
            </Box>
        </DialogContent>

        <DialogActions  style={{padding: '40px', justifyContent: 'flex-start'}}>
          <Button variant="contained" color="primary" className={classes.blueButton} onClick={handleSave}>Submit</Button>
          <Button className={classes.cancelButton} onClick={isCreate?handleClose:handleDeleteLayer}>Delete</Button>
        </DialogActions>
    </Dialog>
  );
}


const condition = authUser => !!authUser;
export default withAuthorization(condition)(LayerModal);
