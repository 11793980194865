import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import logo from "../../images/logo.svg";

const styles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	progess: {
		position: 'absolute'
	}
});

const INITIAL_STATE = {
  email: '',
  password: '',
  error_code: '',
  error_message: '',
  loading: false
};


class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      INITIAL_STATE
    };
  }

  onSubmit = event => {
    const { email, password } = this.state;
    this.setState({ loading: true });
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
		localStorage.setItem("currentuser", email);   
        this.setState({ ...INITIAL_STATE });
        //this.props.history.push(ROUTES.COLORSETS);
		window.location.href = ROUTES.DESIGNS;
      })
      .catch(error => {
        this.setState({ error_code: error.code , error_message: error.message, loading:false });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error_code, error_message, loading } = this.state;
    const { classes } = this.props;

    return (
	<Container component="main" maxWidth="xs">
		<CssBaseline />
		<div className={classes.paper}>
			<img src={logo} style={{width: '100px', marginBottom: '30px'}}/>
			<Typography component="h1" variant="h5" style={{fontWeight: '500'}}>
			Creator Studio Login
			</Typography>
			<form className={classes.form} noValidate>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					id="email"
					label="Email Address"
					name="email"
					autoComplete="email"
					autoFocus
					helperText={error_code}
					error={error_code === 'auth/user-not-found' ? true : false}
					onChange={this.onChange}
				/>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					name="password"
					label="Password"
					type="password"
					id="password"
					autoComplete="current-password"
					helperText={error_code}
					error={error_code === 'auth/wrong-password' ? true : false}
					onChange={this.onChange}
				/>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					style={{backgroundColor: 'black', color: 'white'}}
					className={classes.submit}
					onClick={this.onSubmit}
					disabled={loading || !this.state.email || !this.state.password}
				>
					Sign In
					{loading && <CircularProgress size={30} className={classes.progess} />}
				</Button>
				{error_message && (
					<Typography variant="body2" className={classes.customError}>
						{error_message}
					</Typography>
				)}
			</form>
		</div>
	</Container>
    );
  }
}

const SignInPage = compose(
  withRouter,
  withFirebase,
  withStyles(styles)
)(SignInFormBase);

export default SignInPage;

