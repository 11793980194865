import React, { useState, useEffect, createRef } from 'react';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import StarIcon from '@material-ui/icons/Star';
import { StoragePath, DEFAULT_LOGO_DIMENSION } from '../Utils/defines';

const dropzoneRef = createRef();

const useHeaderStyles = makeStyles((theme) => ({
    logoWrap: {
        padding: '0 15px 10px 0'
    },
    overlayBox: {
        position: 'absolute',
        left: '0',
        top: '0',
        width: '100%',
        height: '100%',
        zIndex: 1,
        background: '#00000',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    },
    starBox: {
      position: 'absolute',
      left: '1px',
      top: '1px',
      width: '20px',
      height: '20px',
      textAlign: 'left',
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      zIndex: 30,
      opacity: 0,
      '& svg': {
        fontSize: '20px',
        lineHeight: '20px',
        color: '#ffffff'
      }
    },
    hoverOverlayBox: {
      position: 'absolute',
      left: '0px',
      top: '0px',
      width: '100%',
      height: '100%',
      textAlign: 'left',
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      zIndex: 20,
      opacity: 0,
    },
    imgBox: {
        position: 'relative',
        textAlign: 'center',
        borderRadius: '8px',
        width: '100px',
        height: '100px',
        border: '2px solid #ced4da',
        background: 'rgba(0,0,0,0.004)',
        cursor: 'pointer',
        overflow: 'hidden',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            objectPosition: 'center center',
            position: 'relative',
            zIndex: 10
        },
        '&:hover': {
          '& $starBox': {

            opacity: 1
          },
          '& $hoverOverlayBox': {
            background: 'rgba(0,0,0,0.4)',  
            opacity: 1
          }
        }
    },
    active: {
      border: '2px solid #007FFF',
      '&:hover': {
        '& $starBox': {
          display: 'none'
        }
      }
    },
    dropBox: {
        position: 'relative',
        textAlign: 'center',
        borderRadius: '8px',
        width: '100px',
        height: '100px',
        border: '2px solid #ced4da',
        background: 'rgba(0,0,0,0.004)',
        cursor: 'pointer',
        '& svg': {
            fontSize: '35px',
            paddingTop: '30px',
            opacity: '0.56'
        }
    }
}));

const existStorageImage = (storageRef, path) => {
    return new Promise((resolve, reject) => {
      var gsReference = storageRef.child(`${StoragePath.profiles}/${path}`);
      gsReference.getDownloadURL()
        .then(url => {
          resolve(true);
        })
        .catch(err => {
          resolve(false);
        });
    });
  }

const uploadToStorage = (storage, imgPath, file) => {
    return new Promise((resolve, reject) => {
      const uploadTask = storage.ref()
        .child(`${StoragePath.profiles}/${imgPath}`)
        .put(file);


      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded
          // and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;

            case "storage/canceled":
              // User canceled the upload
              break;

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
            default:
          }
        },
        () => {
          resolve();        
        }
      );      
      
    });
}

function Logo(props) {
    const {storage, data, icon, active, onClick, onClickIcon} = props;
    const [imagePath, setImagePath] = useState("");
    const classes = useHeaderStyles();
    
    useEffect(() => {
       if (data.path !== "") {

            const ref = storage.ref().child(`${StoragePath.profiles}/${data.path}`);
            ref.getDownloadURL().then(url => {
                setImagePath(url);
            })
            .catch(error => {
                console.log(error);
            });
       }
    }, [data]);

    const handleClick = (e) => {
      e.preventDefault();
      console.log('Click Logo Box');
      if (onClick)
        onClick(data.id, data.type);
    }

    const handleClickStar = (e) => {
      e.preventDefault();
      e.stopPropagation();
      console.log('Click Star Box');
      if (onClickIcon)
        onClickIcon(data.id, data.type);
    }

    return (
      <Box className={classes.logoWrap}>
          <Box className={`${classes.imgBox} ${active?classes.active:""}`} >
            {!imagePath?<CircularProgress />:<img src={imagePath} />}

            {data.preview !== "" && <Box className={classes.overlayBox} style={{background:data.preview}}></Box>}

            <Box className={classes.hoverOverlayBox} onClick={handleClick}></Box>

            {icon && (
            <Box className={classes.starBox} onClick={handleClickStar}>
              <StarIcon />
            </Box>
            )}
          </Box>            
      </Box>
    );
}

function LogoUploader(props) {
    const {storage, type, onComplete} = props;

    const classes = useHeaderStyles();

    const handleDrop = (file) => {   
        const fr = new FileReader();
        fr.onload = function() {
            const img = new Image();
            img.onload = function() {
                existStorageImage(storage.ref(), file.name)
                    .then(exist => {
                        console.log(`Image Art '${file.name}' exist:${exist}`);
                        let filename = file.name;
                        if (exist) {
                            const _filename = filename.split('.').slice(0, -1).join('.');
                            const ext = filename.substr(filename.lastIndexOf('.') + 1);
                            filename = `${_filename}-${Date.now()}.${ext}`; 
                        } 

                        uploadToStorage(storage, filename, file)
                            .then(() => {
                                console.log('Uploaded');
                                onComplete(type, filename, img.width, img.height);
                            });
                    }); 
            };
            img.src = fr.result;
        }
        fr.readAsDataURL(file);
    }

    return (
        <Box className={classes.logoWrap}>
            <Dropzone 
                ref={dropzoneRef} 
                onDrop={(files) => handleDrop(files[0])} 
                multiple={false}>
                {({getRootProps, getInputProps}) => (
                <Box {...getRootProps()} className={classes.dropBox}>
                    <input {...getInputProps()} accept="image/*"/>
                    <CloudUploadIcon />
                </Box>
                )}
            </Dropzone> 
        </Box>
       
    )

}


export {Logo, LogoUploader};