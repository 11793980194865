import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import {isCrew, isLow} from '../Utils/functions';
import {collections, CANVAS_SIZE, CanvasDimensions, StoragePath} from '../Utils/defines';
import {getLogos, getAllImages, createPreview} from '../Utils/canvas';
import ImageTint from '../ImageTint';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SoloFrontImg from "../../images/solo-overlay-front.png";
import SoloBackImg from "../../images/solo-overlay-back.png";
import LowFrontImg from "../../images/low-overlay-front.png";
import LowBackImg from "../../images/low-overlay-back.png";
import { ref, getDownloadURL } from "firebase/storage";

import 'react-lazy-load-image-component/src/effects/blur.css';

const useHeaderStyles = makeStyles((theme) => ({
    closeButton: {
      position: 'absolute',
      right: '10px',
      top: '10px',
      color: '#000000',
    },
    inputRoot: {
      width: '100%',
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '1.5px',
      color: '#000000',
      background: 'rgba(0,0,0,0.04)',
      padding: '11px 15px',
      borderRadius: '4px'
    },
    inputLabel: {
      fontSize: '16px',
      fontWeight: '500',
      color: '#000000'
    },
    addButton: {
      background: '#3D3D3D',
      padding: '8px 32px',
      marginRight: '15px',
      borderRadius: '6px',
      fontSize: '16px',
      fontWeight: '500',
      color: '#FFFFFF',
      textTransform: 'initial'
    },
    mintWrap: {
      marginTop: '30px',
      marginBottom: '40px',
      padding: '0 40px'
    },
    previewCheck: {
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 1000000,
      display: "inline-block",
      width: "auto!important",
      height: "auto!important"
    },
    blueButton: {
      background: '#14b67a',
      padding: '8px 56px',
      borderRadius: '6px',
      fontSize: '16px',
      fontWeight: '500',
      marginLeft: '40px!important',
      color: '#FFFFFF',
      textTransform: 'initial'
    },
    layerButton: {
      background: '#14b67a',
      padding: '8px 56px',
      borderRadius: '6px',
      fontSize: '16px',
      fontWeight: '500',
      color: '#FFFFFF',
      textTransform: 'initial'
    },
    mintButWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '5px'
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      fontSize: "14px",
      fontWeight: "500"
    },
    frontArt: {
      display: 'flex',
      alignItems: 'center',
      fontSize: "14px",
      fontWeight: "500",
      cursor: 'pointer'
    },
    greenButton: {
      background: '#1F7CF2',
      padding: '8px 56px',
      borderRadius: '6px',
      fontSize: '16px',
      fontWeight: '500',
      color: '#FFFFFF',
      textTransform: 'initial'
    },
    mintPreview: {
      
    },
    accordion: {
      border: "none", 
      boxShadow: "none", 
      margin: 0
    },
    accordionIcon: {
      marginRight: 0,
      marginLeft: "0",
      fontSize: "30px",
      color: "black",
    },
    modal_title: {
      fontSize: '32px', 
      margin: 0, 
      fontWeight: '500'
    },
    modal_label: {
      fontSize: '15px', 
      display: 'block',
      fontWeight: '500',
      textTransform: 'initial'
    },
    heading: {
      fontSize: '24px',
      fontWeight: '500',
      color: 'black',
      position: 'relative'
    },
    back: {
      position: "absolute",
      left: 0,
      top: '50%'
    },
    backIcon: {
      width: '16px'
    },
    backWrap: {
      top: "50%",
      transform: "translateY(-50%)",
      left: 0,
      position: "absolute",
      fontSize: "24px",
      display: "flex",
      cursor: "pointer",
      alignItems: "center"
    },
    button_label: {
      textTransform: 'Capitalize'
    },
    imageBox: {
  
    },
    infoBox: {
        paddingBottom: '20px',
        borderBottom: '1px solid #eeeeee'
    },
    previewBox: {
      position: 'relative',
      paddingTop: `${CANVAS_SIZE.height * 100.0/CANVAS_SIZE.width}%`,
      border: '2px solid rgb(239, 239, 239)'
    },
    previewBoxLow: {
      position: 'relative',
      paddingTop: `${3000* 100.0/1725}%`,
      border: '2px solid rgb(239, 239, 239)'
    },
    previewCanvas: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%'
    },
    overlayImg: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      zIndex: '100'
    },
    loadingImg: {
      position: 'absolute',
      left: 0,
      top: '-1px',
      width: '100%',
      zIndex: '1000'
    },
    circleImg: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    overlayBox: {
      position: 'relative',
      paddingTop: '92.667%'
    }, 
    overlayBoxLow: {
      position: 'relative',
      paddingTop: '92.667%'
    },       
    fullImg: {
      width: '100%',
      height: 'auto'
    },
    fillImg: {
      width: '100%',
      height: '100%'
    },
    imgsBox: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '-5px',
      marginRight: '-5px',
    },
    imgItem: {
      width: '50%',
      padding: '0 5px'
    },
    soloItem: {
      width: '50%'
    },
    previewContent: {
      position: 'relative',
      width: '100%',
      overflow: 'hidden'
    },
    artBox: {
      
    }
}));


  function DesignCanvas(props) {
    const { profile, layers, colorSets, neutrals, overlay, isFront, format, firebase } = props;

    const [loadingLogos, setLoadingLogos] = useState(false);
    const [loadingImages, setLoadingImages] = useState(false);
    const [images, setImages] = useState([]);
    const [logos, setLogos] = useState([]);

    const canvasRef = useRef();
    const classes = useHeaderStyles();
    const storage = firebase.getstorage();

    useEffect(() => {
      if (profile) {
        setLoadingLogos(true);
      }
    }, [profile]);

    useEffect(() => {
      if (layers.length>0)
        setLoadingImages(true);
    }, [layers]);

    useEffect(() => {
      if (loadingLogos) {
        loadLogos();
      } 
      if (loadingImages) {
        loadImages();
      }       

      drawCanvas();
    }, [loadingLogos, loadingImages, isFront]);


    const loadImages = async () => {
      console.log('Loading images...');
      
      let imgs = [...images];
      //fetch all layers necessary to load image
      
      const _layers = _.filter(layers, layer => {
        if (layer.assetType !== 'graphic')
          return false;
        let img = _.find(images, {layer: layer.id});
        if (!img)
          return true;
        return img.name !== layer.image;
      });

      if (_layers.length > 0) {
        const _imgs = await getAllImages(storage, _layers);
        _.each(_imgs, item => {
          let img = _.find(imgs, {layer: item.layer});
          if (!img) {
            imgs.push(item);
          } else {
            img.image = item.image;
            img.name = item.name;
          }
        });
  
        setImages([...imgs]);
      }

      setLoadingImages(false);
      console.log('Loading images completed!');
    }
  
    const loadLogos = async () => {
      console.log('Loading logos...');      
      const _logos = await getLogos(storage, profile);    
      setLogos([..._logos]);
      setLoadingLogos(false);
      console.log('Loading logos completed!');
    }

    const drawCanvas = () => {
      if (loadingImages || loadingLogos)
        return;

      const canvas = canvasRef.current;
      if (!canvas) {
          return; 
      }

      clearRect(canvas);
      if (images.length) {
        createPreview(canvas, layers, images, colorSets, neutrals, isFront);
      }
        
    }

    const clearRect = (canvas) => {
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }

    const renderOverlay = (isFront) => {
      let img = isFront?SoloFrontImg:SoloBackImg;      
      if (isLow(format))
        img = isFront?LowFrontImg:LowBackImg;
      return (
        <Box className={classes.overlayImg}>
          <img src={img} className={classes.fillImg} />
        </Box>
      )
    }

    const renderLoadingIcon = () => {
      return (
        <Box className={classes.loadingImg}>
          <LinearProgress />
        </Box>
      )
    }    
    const dimension = _.find(CanvasDimensions, {format: format});
    return (
      <Box className={isCrew(format)?classes.previewBox:classes.previewBoxLow}>
        {(loadingLogos || loadingImages) && renderLoadingIcon()}
        {overlay && renderOverlay(isFront)}
        <canvas 
          className={classes.previewCanvas}
          ref={(node) => {
            canvasRef.current = node;            
          }}
          width={dimension.width}
          height={dimension.height}
        ></canvas>
      </Box>
    );

  }

  function CanvasImage(props) {
    const {firebase, layer} = props;    
    const [url, setUrl] = useState("");
  
    useEffect(() => {
      if (props.path === "") {
        setUrl("");
      } else {
        console.log('New Path: ', props.path);
        
        const storage = firebase.getstorage();
        getDownloadURL(ref(storage, `${StoragePath.images}/${props.path}`))
        .then(url => {
          setUrl(`${url}&${Date.now()}`);
        })
        .catch(error => {
          console.log(error);
        });
      }
  
    }, [props.path, props.color]);
  
    const left = (parseFloat(layer.pos.x)/CANVAS_SIZE.width) * 100; 
    const top = (parseFloat(layer.pos.y)/CANVAS_SIZE.height) * 100; 
    const width = (parseFloat(layer.pos.width)/CANVAS_SIZE.width) * 100; 
    const height = (parseFloat(layer.pos.height)/CANVAS_SIZE.height) * 100; 
  
    const style  = {position: 'absolute', left:`${left}%`, top:`${top}%`,  width:`${width}%`, height:`${height}%`, opacity: `${layer.opacity/100.0}`, zIndex: layer.zindex, transform: `rotate(${layer.rotate}deg)`};
  
    console.log('Canvas Image: Path: ', props.path);
    console.log('Canvas Image: Color: ', props.color);
    return (
      <Box key={`${layer.path}`} className="tint-wrap" style={style} >
        {url !== "" && (
          layer.path === 'none'?<img src={url} />:<ImageTint src={url} color={props.color}  cache={false} />      
        )}
      </Box>
    );
  }

  function BucketImg(props) {
    const {firebase, path, loading} = props;
    const [imagePath, setImagePath] = useState("");    
    const classes = useHeaderStyles();
    useEffect(() => {
      if (!loading && path!=="") {
        const storage = firebase.getstorage();
        getDownloadURL(ref(storage, path))
        .then(url => {
          var img = new Image();
          img.onload = function(){
            const width = this.width;
            const height = this.height;
            setImagePath(url);  
          };
          img.src = url;
        })
        .catch(error => {
          console.log(error);
        });
      }     
  
    }, [path, loading]);

    return (
        <Box className={classes.artBox}>
            <Box className={classes.overlayBox}>
              {loading?(
                <Box className={classes.circleImg}>
                  <CircularProgress />
                </Box>
              ):(
                <Box className={classes.overlayImg} display="flex" alignItems="center" justifyContent="center">
                  <LazyLoadImage alt={path} effect="blur" src={imagePath} className={classes.fullImg}/>
                </Box>
              )}
            </Box>
        </Box>
    );
  }

  export { DesignCanvas, CanvasImage, BucketImg };