import {collections} from './defines';
import _  from "lodash";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";

const getPatterns = (db, callback) => {
  const q = query(collection(db, collections.PATTERNS), orderBy('name'));

  return getDocs(q)
  .then((querySnapshot) => {
    let items = [];
    querySnapshot.forEach((doc) => {
      items.push({...doc.data(), id:doc.id});
    });
    callback(items);
  })
}


  const deletePattern = (db, item) => {
    db.collection(collections.PATTERNS).doc(item.id).delete();  
  }

  const createPattern = (db, item) => {
    const currentTime = Date();
  
    db.collection(collections.PATTERNS).add({
      created: currentTime,
      name: item.name,
      items: item.items
    });
  }
  
  const updatePattern = (db, item) => {
    db
      .collection(collections.PATTERNS)
      .doc(item.id)
      .update({
        name: item.name,
        items: item.items
      });
  }

  export { getPatterns, deletePattern, createPattern, updatePattern };