import React, {useState} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _  from "lodash";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: '30px'
    },
    filterButton: {
        '& span': {
            color: '#000000',
            fontSize: '14px',
            letterSpacing: '1.5px'
        }        
    },
}));


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    minWidth: '120px',
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      color: '#000000',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#000000',
      },
    },
  },
}))(MenuItem);

export default function FilterDropdown(props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (item_id) => {
    setAnchorEl(null);
    props.onSelect(item_id);
  };

  const items = [...props.items];
  const activeItem = _.find(items, item => item.id==props.active);
  return (
    <div className={classes.root}>
      <Button
        className={classes.filterButton}
        aria-controls="customized-menu"
        aria-haspopup="true"        
        onClick={handleClick}
        endIcon={Boolean(anchorEl)?<ExpandLessIcon />:<ExpandMoreIcon />}
      >
        {props.title}: {activeItem.name}
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((item) => (
        <StyledMenuItem selected={item.id===activeItem.id} key={`Filter-${activeItem.name}-${item.id}`} onClick={e => handleItemClick(item.id)}>
            <ListItemText  primary={item.name} />
        </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}
