import React, { useState, useEffect } from 'react';
import { makeStyles  } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import _  from "lodash";
import { StoragePath } from '../Utils/defines';
import { withAuthorization } from '../Session';
import { BucketImg } from '../Designs/artComponents';


const useStyles = makeStyles((theme) => ({
    itemBox: {
        border: '2px solid #EEEEEE',
        background: '#FFFFFF',
        borderRadius: '12px'
    },
    textSKU: {
        fontWeight: '500',
        color: '#000000',
        textTransform: 'uppercase',
        fontSize: '18px',
        lineHeight: 1.5
    },
    textName: {
        fontWeight: '300',
        fontStyle: 'italic',
        color: '#000000',
        textTransform: 'uppercase',
        fontSize: '16px',
        lineHeight: 1
    },
    textSize: {
        fontWeight: '300',
        color: '#000000',
        textTransform: 'uppercase',
        fontSize: '15px',
        lineHeight: 1.5
    },
    specBox: {
        padding: '10px 20px 20px'
    },
    titleBox: {
        padding: '5px 20px 5px',
        minHeight: '90px'
    },
    customBox: {
        minHeight: '22px',
        padding: '7px 20px'
    },
    fullImg: {
        width: '100%',
        height: 'auto'
    },
    sizeText: {
        fontSize: '18px',
        lineHeight: '24px',
        color: '#000000',
        textAlign: 'center'
    },
    artBox: {
        
    },
    coverImage: {
        position: 'absolute',
        width: '100%',        
        height: '100%',
        left: 0,
        top: 0
    },
    overlayBox: {
        position: 'relative',
        paddingTop: '71.6%'
    },
    button: {
        width: '80px',
        background: '#1f7cf2',
        color: '#FFFFFF',
        textTransform: 'initial'
    }
}));

// primary row component
function PublishedGridItem(props) {
    const { data, firebase, product, label, handleClick } = props;
    const classes = useStyles();

    const renderArts = () => {
        const path = `${StoragePath.products}/${data.product}.png`;
        return (
            <Box style={{padding: '20px 10px'}}>
                <BucketImg 
                    loading={!product || !product.shopifyCreated}
                    firebase={firebase} 
                    path={path}
                />
            </Box>
        );
    }

    const handleClickItem = (event) => {
        if (handleClick)
            handleClick(data);        
    }

    return (
        <Box className={classes.itemBox}>
            {renderArts()}

            <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.specBox} >
                <Box>
                    <Typography className={classes.textSKU}>{data.name}</Typography>
                    <Typography className={classes.textName}>SOLO</Typography>
                    <Typography className={classes.textSize}>{data.format}</Typography>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        className={classes.button}
                        onClick={handleClickItem}
                    >{label}</Button>
                </Box>
            </Box>
        </Box>
    )
  }

export default PublishedGridItem;
