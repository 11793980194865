import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import SignInPage from '../SignIn';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ProfilesPage from '../Profiles';
import DesignsPage from '../Designs';
import PatternsPage from '../Patterns';
import ColorSetsPage from '../ColorSets';
import NeutralsPage from '../Neutrals';
import ProductsPage from '../Products';
import PublishedPage from '../Published';
import NotFoundPage from '../404';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
    h1: {
      marginTop: '0',
      marginBottom: '12px',    
      fontSize: '32px',
      fontWeight: 500,
      letterSpacing: 'normal',
      color: '#000000'
    }
  }
});

const App = () => (
  <Router>
    <ThemeProvider theme={theme}>
      <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route exact path={ROUTES.PROFILES} component={ProfilesPage} />
      <Route exact path={ROUTES.DESIGNS} component={DesignsPage} />
      <Route exact path={ROUTES.PATTERNS} component={PatternsPage} />
      <Route exact path={ROUTES.COLORSETS} component={ColorSetsPage} />
      <Route exact path={ROUTES.NEUTRALS} component={NeutralsPage} />
      <Route exact path={ROUTES.PRODUCTS} component={ProductsPage} />
      <Route exact path={ROUTES.PUBLISHED} component={PublishedPage} />
      
    </ThemeProvider>
  </Router>
);

export default withAuthentication(App);
