import {collections} from './defines';
import _  from "lodash";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";

const getNeutrals = (db, callback) => {
  const q = query(collection(db, collections.NEUTRALS), orderBy('name'));

  return getDocs(q)
  .then((querySnapshot) => {
    let items = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      items.push({...doc.data(), id:doc.id});
    });
    
    callback(items);
  })
}

const updateNeutral = (db, itemId, color) => {
  db
    .collection(collections.NEUTRALS)
    .doc(itemId)
    .update({color});
}

export { getNeutrals, updateNeutral };