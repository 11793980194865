export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';

export const PROFILES = '/profiles';
export const COLLECTIONS = '/partners';
export const DESIGNS = '/';
export const PRODUCTS = '/products';
export const PUBLISHED = '/published';
export const COLORSETS = '/colorsets';
export const PATTERNS = '/patterns';
export const NEUTRALS = '/neutrals';

