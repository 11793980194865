import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Input from '@material-ui/core/Input';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import {ColorInput, ColorBox, ColorButton} from 'material-ui-color';
import { withAuthorization } from '../Session';
import Layout from '../Page';
import {updateNeutral, getNeutrals} from '../Utils/neutrals';


const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: '5px',
        width: '100%'
      },
      'label': {
        fontSize: '16px',
        fontWeight: '500',
        color: '#000000'
      }
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 10px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    }
  }))(InputBase);
  
const useHeaderStyles = makeStyles((theme) => ({
    closeButton: {
      position: 'absolute',
      right: '10px',
      top: '10px',
      color: '#000000',
    },
    inputRoot: {
      width: '100%',
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '1.5px',
      color: '#000000',
      background: 'rgba(0,0,0,0.04)',
      padding: '11px 15px',
      borderRadius: '4px'
    },
    inputLabel: {
      fontSize: '16px',
      fontWeight: '500',
      color: '#000000'
    },
    addButton: {
      background: '#3D3D3D',
      padding: '8px 32px',
      marginRight: '15px',
      borderRadius: '6px',
      fontSize: '16px',
      fontWeight: '500',
      color: '#FFFFFF',
      textTransform: 'initial'
    },
    blueButton: {
      background: '#1F7CF2',
      padding: '8px 56px',
      borderRadius: '6px',
      fontSize: '16px',
      fontWeight: '500',
      color: '#FFFFFF',
      textTransform: 'initial'
    },
    accordion: {
      border: "none", 
      boxShadow: "none", 
      margin: 0
    },
    accordionIcon: {
      marginRight: 0,
      marginLeft: "0",
      fontSize: "30px",
      color: "black",
    },
    modal_title: {
      fontSize: '32px', 
      margin: 0, 
      fontWeight: '500'
    },
    modal_label: {
      fontSize: '16px', 
      display: 'block',
      fontWeight: '500'
    },
    heading: {
      fontSize: '24px',
      fontWeight: '500',
      color: 'black',
      paddingLeft: "15px"
    },
    dropButton: {
      fontSize: '16px',
      fontWeight: '500',
      color: '#FFFFFF',
      width: '100%',
      background: '#3D3D3D',
      borderRadius: '6px',
      textTransform: 'Capitalize',
      height: '40px',
      padding: '0',
      marginTop: '30px'
    },
    colorLabelBox: {
      display: 'flex',
      alignItems: 'center',
      minHeight: '30px'
    },
    colorLabel: {
      fontSize: '15px',
      fontWeight: 500,
      color: 'black',
      lineHeight: '1',
      letterSpacing: '-0.8px',    
    },
    colorBoxWrap: {
      padding: '10px 15px',
    },
    colorBox: {
      width: 60,
      height: 60,
      borderRadius: 5,
    },
    noColorBox: {
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      borderRadius: 5,
      border: 'solid 1px rgba(217, 223, 233, 0.4)',
      boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)',
      backgroundColor: 'transparent',
      backgroundImage: 'gradient(linear, 19.1% -7.9%, 81% 107.9%, color-stop(0, transparent), color-stop(.48, transparent), color-stop(.5, #000), color-stop(.52, transparent), color-stop(1, transparent))',
      backgroundImage: 'repeating-linear-gradient(135deg, transparent 0%, transparent 48%, #ff0000 50%, transparent 52%, transparent 100%)',
      '&:hover': {
        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        backgroundColor: '#d5d5d5'
      },
    },
  
    labelBox: {
      width: 'calc(100% - 80px)',
      paddingLeft: '12px',
      '& label': {
        display: 'none'
      },
      '& input': {

      }
    },
    colorFlex: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    colorFlex2: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '40px',
      maxWidth: '475px'
    },
    colorFlexItem: {
      width: '20%'
    },
    colorFlexItem2: {
      width: '50%'
    },
    attrWrapper: {
      margin: '0 -12px'
    },
    logoWrapper: {
      flexGrow: 1, 
      bgcolor: 'background.paper', 
      display: 'flex', 
      height: 250, 
      width: '100%',
      border: '1px solid #ced4da',
      borderRadius: 5
    },
    logoTab: {
      color: 'black!important',
      fontFamily: 'Poppins!important',
      fontWeight: '500!important',
      textTransform: 'initial!important'
    },
    logoList: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    tagList: {
      display: 'flex',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      marginTop: '16px',
      '& li': {
        marginBottom: '10px'
      }
    },
    chip: {
      marginRight: '16px',
      borderRadius: '6px',
      background: '#3d3d3d',
      height: '40px',
      padding: '0 15px 0 15px'
    },
    chipLabel: {
      fontSize: '16px',
      fontWeight: '500',
      color: '#FFFFFF'
    },
    chipButton: {
      color: '#ffffff',
      opacity: '0.4',
      marginLeft: '15px',
      padding: '0',
      '&:hover': {
        opacity: '1',
      }
    },  
    mainBox: {
        maxWidth: '700px',
        marginTop: '60px'
    }
}));
  
  /* Color Item */
  function ColorItem(props) {
    const {classes, label, onChange} = props;
  
    const [color, setColor] = useState(props.color);
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    useEffect(() => {
      if (onChange) {
        onChange(label, color);
      }
        
    }, [color]);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleChangeColor = (value) => {
      if (value == undefined)
        return;
  
      console.log('New Color: ', value);
      setColor(`#${value.hex}`);
    }
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  
    return (
      <Box display="flex" className={classes.colorBoxWrap}>
        <Box className={classes.colorBox} onClick={handleClick}>
          {color?(
            <ColorButton color={color} size={60} />
          ):(
            <Box className={classes.noColorBox}></Box>
          )}        
        </Box>
        <Box className={classes.labelBox}>
          <Box className={classes.colorLabelBox}>
            <Typography className={classes.colorLabel}>{label}</Typography>
          </Box>        
          {/*color?<ColorInput defaultValue={color} format="hex" value={color} />:<ColorInput defaultValue={color} value={color} />*/}
          <Input placeholder="Color" defaultValue={color?color:'#'} value={color?color:'#'} onChange={e => setColor(e.target.value)} style={{width:'100%'}}/>
        </Box>
  
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <ColorBox defaultValue={color} onChange={handleChangeColor}/>
        </Popover>      
      </Box>
    )
  }
function Neutrals(props) {  
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);  

  const db = props.firebase.getdb();

  useEffect(() => {
    getNeutrals(db, fetchData);
  }, []);

  
  const fetchData = (items) => {
    console.log('Fetch Data');
    setData([...items]);
    setLoading(false);
  };

  const changeColor = (label, color) => {
    const item = _.find(data, {name: label});
    updateNeutral(db, item.id, color);
  }

  const classes = useHeaderStyles();
  

  return (
    <Layout page="colorsets" >
      <div className="pageHeader">
        <div className={classes.pageHeading}>
          <Typography variant="h1">Neutrals</Typography>          
        </div>             
      </div>

      {data.length > 0 && (
        <Box className={classes.mainBox}>
            <Grid container spacing={3}>
                <Grid item xs={5}>
                    <ColorItem 
                        classes={classes} 
                        label={data[0].name} 
                        color={data[0].color}
                        onChange={changeColor}
                    />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={5}>
                    <ColorItem 
                        classes={classes} 
                        label={data[3].name} 
                        color={data[3].color}
                        onChange={changeColor}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={5}>
                    <ColorItem 
                        classes={classes} 
                        label={data[1].name} 
                        color={data[1].color}
                        onChange={changeColor}
                    />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={5}>
                    <ColorItem 
                        classes={classes} 
                        label={data[4].name} 
                        color={data[4].color}
                        onChange={changeColor}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={5}>
                    <ColorItem 
                        classes={classes} 
                        label={data[2].name} 
                        color={data[2].color}
                        onChange={changeColor}
                    />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={5}>
                    <ColorItem 
                        classes={classes} 
                        label={data[5].name} 
                        color={data[5].color}
                        onChange={changeColor}
                    />
                </Grid>
            </Grid>            
        </Box>
      )}


    </Layout>
  );
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(Neutrals);
