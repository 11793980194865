import React, { useState, useEffect } from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import LoadingButton from "@mui/lab/LoadingButton"
import Box from "@material-ui/core/Box"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"

import { withAuthorization } from "../Session"
import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider"
import SwapHorizSharpIcon from "@material-ui/icons/SwapHorizSharp"
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline"
import VisibilityIcon from "@material-ui/icons/Visibility"
import Select from "@material-ui/core/Select"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Popover from "@mui/material/Popover"
import Paper from "@mui/material/Paper"
import MenuList from "@mui/material/MenuList"
import ListItemText from "@mui/material/ListItemText"
import ListItemIcon from "@mui/material/ListItemIcon"

import ContentCut from "@mui/icons-material/ContentCut"
import ContentCopy from "@mui/icons-material/ContentCopy"
import ContentPaste from "@mui/icons-material/ContentPaste"
import Cloud from "@mui/icons-material/Cloud"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import Checkbox from "@mui/material/Checkbox"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"

import Layer from "./layer"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import _ from "lodash"
import { collections, DEFAULT_DESIGN, DEFAULT_PRODUCT } from "../Utils/defines"
import { isCrew, isLow } from "../Utils/functions"
import { createDesign, updateDesign, deleteDesign, updateDesignItem } from "../Utils/designs"
import { getLayers, updateLayers, deleteDesignLayers } from "../Utils/layers"
import LayerModal from "./layerModal"
import ProductModal from "./productModal"
import { DesignCanvas } from "./artComponents"
import { createProduct, getProduct, updateProduct } from "../Utils/products"

import BootstrapInput from "../BootstrapInput"
import BootstrapInputBig from "../BootstrapInputBig"

const useDesignModalStyles = makeStyles(theme => ({
  closeButton: {
    position: "absolute",
    right: "10px",
    top: "10px",
    color: "#000000",
  },
  inputRoot: {
    width: "100%",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "1.5px",
    color: "#000000",
    background: "rgba(0,0,0,0.04)",
    padding: "11px 15px",
    borderRadius: "4px",
  },
  inputLabel: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#000000",
  },
  addButton: {
    background: "#14b67a",
    padding: "8px 25px",
    borderRadius: "6px",
    fontSize: "16px",
    fontWeight: "500",
    color: "#FFFFFF",
    textTransform: "initial",
    marginRight: "20px",
  },
  colorsetButton: {
    width: "100%",
    marginTop: "5px",
    background: "rgba(0,0,0,0.04);",
    borderRadius: "4px",
    fontSize: "16px",
    border: "1px solid #ced4da",
    padding: "5.5px 12px",
    fontWeight: 400,
    justifyContent: "flex-start",
    "& .MuiButton-endIcon": {
      position: "absolute",
      right: "12px",
    },
  },
  saveButton: {
    background: "#1F7CF2",
    padding: "10px 40px!important",
    borderRadius: "6px!important",
    fontFamily: "Poppins!important",
    fontSize: "16px !important",
    textTransform: "capitalize!important",
    fontWeight: "500",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#1F7CF2",
      opacity: 0.7,
    },
  },
  publishButton: {
    background: "#14b67a",
    padding: "8px 25px",
    borderRadius: "6px",
    fontSize: "16px",
    fontWeight: "500",
    color: "#FFFFFF",
    textTransform: "initial",
    marginLeft: "20px",
    "&:hover": {
      backgroundColor: "#14b67a",
      opacity: 0.7,
    },
  },
  modal_label: {
    fontSize: "15px",
    display: "block",
    fontWeight: "500",
    textTransform: "initial",
  },
  back: {
    position: "absolute",
    left: 0,
    top: "50%",
  },
  button_label: {
    textTransform: "Capitalize",
  },
  designHeader: {
    padding: "10px 0 20px",
    borderBottom: "1px solid #eeeeee",
  },
  previewBox: {
    position: "relative",
    width: "210px",
    margin: "0 auto",
  },
  previewBoxLow: {
    position: "relative",
    width: "330px",
    margin: "0 auto",
  },
  visibilityIcon: {
    position: "absolute",
    right: "-50px",
    bottom: "0px",
    fontSize: "0",
    cursor: "pointer",
  },
  deleteIcon: {
    position: "absolute",
    bottom: "20px",
    fontSize: "0",
    cursor: "pointer",
  },
  sideLabel: {
    fontSize: "25px",
    fontWeight: 500,
  },
  designLayers: {
    height: "calc(100% - 280px)",
    maxHeight: "335px",
    overflow: "hidden",
    overflowY: "auto",
  },
  designLayersWrapper: {},
  designFooter: {
    padding: "10px 0",
    textAlign: "right",
  },
  designControl: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0 0",
  },
}))

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function descendingComparator(a, b, orderBy) {
  let aVal, bVal
  aVal = a[orderBy]
  bVal = b[orderBy]

  if (bVal < aVal) {
    return -1
  }
  if (bVal > aVal) {
    return 1
  }
  return 0
}

let historyData = []
let lastAction = 0

function DesignModal(props) {
  const { handleClose, open, isCreate, colorSets, profiles, neutrals } = props
  const [design, setDesign] = useState({ ...DEFAULT_DESIGN })
  const [profile, setProfile] = useState(null)
  const [isFront, setIsFront] = useState(true)
  const [layers, setLayers] = useState([])
  const [openLayerModal, setOpenLayerModal] = useState(false)
  const [openProductModal, setOpenProductModal] = useState(false)
  const [updateLayerId, setUpdateLayerId] = useState("")
  const [overlay, setOverlay] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [error, setError] = useState({ message: "", label_red: false })
  const [isSaving, setIsSaving] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const openColorSets = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseColorSets = () => {
    setAnchorEl(null)
  }

  const db = props.firebase.getdb()

  useEffect(() => {
    const downHandler = e => {
      if (e.ctrlKey && e.keyCode == 90) {
        //lastAction = Date.now();
        setTimeout(() => {
          handleUndo()
        }, 1000)
      }
    }

    window.addEventListener("keydown", downHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler)
    }
  }, [])

  useEffect(() => {
    if (open) {
      historyData = []
      lastAction = Date.now()
      setOverlay(false)
      setLayers([])

      if (isCreate) {
        setDesign({ ...DEFAULT_DESIGN })
      } else {
        setDesign({ ...props.item })
        getLayers(db, props.item.id, fetchLayerData)
      }

      if (profiles.length > 0) setProfile({ ...profiles[0] })
    }
  }, [open])

  const handleCloseLayerDialog = () => {
    setOpenLayerModal(false)
  }

  const handleCloseProductModal = () => {
    setOpenProductModal(false)
  }

  const handleCloseUpdateLayerDialog = () => {
    setUpdateLayerId("")
  }

  const handlePublishProduct = async () => {
    /*
    const _design = {...design, product:productId};
    setDesign({..._design});
    const item = {product:productId, colorSets:design.colorSets}
    updateDesignItem(db, _design.id, item);
    */
    if (design.product === "") {
      const product = {
        ...DEFAULT_PRODUCT,
        colorSet: design.colorSets[0],
        design: design.id,
        format: design.format,
        name: design.name,
        sku: design.sku,
      }
      const productId = await createProduct(db, product)
      updateDesign(db, { ...design, product: productId, status: "published" })
      setDesign({ ...design, status: "published", product: productId })
    } else {
      const product = await getProduct(db, design.product)
      await updateProduct(db, design.product, {
        ...product,
        colorSet: design.colorSets[0],
        design: design.id,
        format: design.format,
        name: design.name,
        sku: design.sku,
      })
      updateDesign(db, { ...design, status: "published" })
    }

    setOpenProductModal(false)
  }

  const handleUndo = () => {
    console.log("Undo Action")
    const timeTick = Date.now() - lastAction
    console.log("Key Matched ", timeTick)

    const lastItem = historyData.pop()
    if (lastItem) {
      console.log("Last Layers: ", lastItem.layers)
      setLayers([...lastItem.layers])
      lastAction = Date.now()
    }
  }

  const fetchLayerData = items => {
    console.log("Fetch Data")
    //historyData.push({index: historyData.length, date: Date.now(), layers: [...items]});

    const _layers = _.map(items, item => {
      return {
        ...item,
        removed: false,
      }
    })
    setLayers(_layers)
  }

  const handleCloseDialog = event => {
    handleClose()
  }

  const handleChangeProfile = event => {
    console.log("handleChange")
    let { name, value } = event.target

    const _profile = _.find(props.profiles, { id: value })
    setProfile({ ..._profile })
  }

  const handleClickColorSet = colorSetId => {
    console.log("Click ColorSet: ", colorSetId)
    /*
    let _colorSets = [];

    const exist = _.find(design.colorSets, dItem => dItem === colorSetId);
    _.each(design.colorSets, item => {
      if (item !== colorSetId)
        _colorSets.push(item);
    })

    if (!exist) {
      _colorSets.push(colorSetId);
    }
     setDesign({...design, colorSets:[..._colorSets]});
*/
    setDesign({ ...design, colorSets: [colorSetId] })
    setAnchorEl(null)
  }

  const renderInformation = () => {
    return (
      <Box className={classes.designHeader}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <label className={classes.modal_label} htmlFor="admin-label-helper">
              Design Name
            </label>
            <BootstrapInputBig
              type="text"
              value={design.name}
              placeholder="Enter Value"
              onChange={e => setDesign({ ...design, name: e.target.value })}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: "5px" }}>
          <Grid item xs={6} sm={6}>
            <label className={classes.modal_label} htmlFor="admin-label-helper">
              SKU
            </label>
            <BootstrapInput
              label="SKU"
              type="text"
              value={design.sku}
              placeholder="Enter Value"
              onChange={e => setDesign({ ...design, sku: e.target.value })}
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <label className={classes.modal_label} htmlFor="admin-label-helper">
              &nbsp;
            </label>

            <Button
              variant="text"
              endIcon={<ArrowDropDownIcon />}
              className={classes.colorsetButton}
              aria-controls={openColorSets ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openColorSets ? "true" : undefined}
              onClick={handleClick}
            >
              Color Sets
            </Button>

            <Popover
              open={openColorSets}
              anchorEl={anchorEl}
              onClose={handleCloseColorSets}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Paper sx={{ width: "340px", maxWidth: "100%" }}>
                <MenuList>
                  {_.map(colorSets, colorSet => {
                    const exist = _.find(design.colorSets, item => item === colorSet.id)
                    return (
                      <MenuItem onClick={e => handleClickColorSet(colorSet.id)} key={`colorSet_${colorSet.id}`}>
                        <ListItemIcon>
                          <Checkbox defaultChecked={exist ? true : false} />
                        </ListItemIcon>
                        <ListItemText>{colorSet.name}</ListItemText>
                        <ListItemIcon>
                          <ModeEditIcon />
                        </ListItemIcon>
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </Paper>
            </Popover>
          </Grid>
        </Grid>
      </Box>
    )
  }

  const handleAddLayer = () => {
    console.log("Add Layer")
    setOpenLayerModal(true)
  }

  const handleNewLayer = layer => {
    console.group("New layer")
    const _layers = [...layers]
    //_layers.push(layer);
    _layers.unshift(layer)
    const newLayers = _.map(_layers, (layer, index) => {
      return { ...layer, pos: { ...layer.pos }, zindex: _layers.length - index }
    })

    setLayers(newLayers)
  }

  const handleUpdate = layer => {
    console.log("Update layer ", layer)
    saveHistory(layers)
    const _layers = _.map(layers, item => {
      if (item.id === layer.id) {
        return { ...layer, pos: { ...layer.pos } }
      }
      return { ...item }
    })

    setLayers(_layers)
  }

  const handleCloseRemoveDialog = () => {
    setOpenDeleteModal(false)
  }

  const handleDeleteLayer = layer_id => {
    console.log("Delete layer ", layer_id)
    saveHistory(layers)
    const _layers = _.map(layers, item => {
      if (item.id === layer_id) {
        return { ...item, removed: true, pos: { ...item.pos } }
      }
      return { ...item }
    })

    setLayers(_layers)
  }

  const handleDeleteDesign = event => {
    setOpenDeleteModal(false)
    deleteDesign(db, design)
    //deleteDesignLayers(db, design);
    handleClose()
  }

  const renderRemoveDialog = () => {
    return (
      <Dialog onClose={handleCloseRemoveDialog} aria-labelledby="revision-dialog-title" open={openDeleteModal}>
        <DialogTitle id="revision-dialog-title" onClose={handleCloseRemoveDialog}>
          Confirmation
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>Do you want to remove current Design ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseRemoveDialog} color="primary">
            No
          </Button>
          <Button onClick={handleDeleteDesign} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  function saveHistory(layers) {
    const _layers = _.map(layers, item => {
      return { ...item, pos: { ...item.pos } }
    })
    //historyData.push({index: historyData.length, date: Date.now(), layers: [..._layers]});
  }

  const handleOnDragEnd = result => {
    console.log("Drag Drop Result: ", result)
    const _layers = [...__layers]

    saveHistory(layers)

    const [reorderedItem] = _layers.splice(result.source.index, 1)
    _layers.splice(result.destination.index, 0, reorderedItem)

    const newLayers = _.map(_layers, (layer, index) => {
      const newZindex = _layers.length - index + 1
      return { ...layer, zindex: newZindex }
    })
    console.log("New Layers: ", _layers)
    setLayers(newLayers)
  }

  const updateLayer = layer => {
    console.log("Update layer ", layer)
    saveHistory(layers)

    const _layers = _.map(layers, item => {
      if (item.id === layer.id) {
        return { ...layer, pos: { ...layer.pos } }
      }

      return { ...item }
    })

    setLayers(_layers)
  }

  const handleOpenLayer = layer_id => {
    console.log("Open modal: ", layer_id)
    setUpdateLayerId(layer_id)
  }

  const handleSaveLayers = async (ret, docId) => {
    if (ret == false) {
      console.log("Db Action failed")
      return
    }

    setDesign({ ...design, id: docId })
    await updateLayers(db, layers, docId)
    setIsSaving(false)
  }

  const handleSave = () => {
    if (!design.name) {
      setError({ ...error, label_red: true, message: "Name is required!" })
    } else if (!design.sku) {
      setError({ ...error, label_red: true, message: "SKU is required!" })
    } else {
      setIsSaving(true)
      if (design.id === "") {
        createDesign(db, design, handleSaveLayers)
      } else {
        updateDesign(db, design, handleSaveLayers)
      }
      setError({ ...error, label_red: false, message: "" })
    }
  }

  const handlePublish = async () => {
    if (!design.name) {
      setError({ ...error, label_red: true, message: "Name is required!" })
      return
    }
    if (!design.sku) {
      setError({ ...error, label_red: true, message: "SKU is required!" })
      return
    }

    if (design.colorSets.length === 0) {
      setError({ ...error, label_red: true, message: "ColorSet is required!" })
      return
    }

    setOpenProductModal(true)
  }

  const renderControl = () => {
    return (
      <Box className={classes.designControl}>
        <Box>
          <Typography className={classes.sideLabel}>{isFront ? "Front" : "Back"}</Typography>
        </Box>
        <Box>
          <Button onClick={e => setIsFront(!isFront)}>
            <SwapHorizSharpIcon /> <span className={classes.button_label}>Switch</span>
          </Button>
        </Box>
      </Box>
    )
  }

  const renderLayers = () => {
    const colorSet = design.colorSets.length > 0 ? _.find(colorSets, { id: design.colorSets[0] }) : null
    return (
      <Box className={classes.designLayers}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="layers">
            {(provided, snapshot) => (
              <div className="layers" ref={provided.innerRef} {...provided.droppableProps}>
                {__layers.map((layer, index) => {
                  return (
                    <Draggable key={`layer_${index}`} draggableId={`layer_${index}`} index={index}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <Layer
                            layers={__layers}
                            layer={layer}
                            isFront={isFront}
                            time={Date.now()}
                            key={"layer1" + index}
                            onUpdate={updateLayer}
                            onOpenModal={handleOpenLayer}
                            colorSet={colorSet}
                          />
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    )
  }

  const renderPreview = () => {
    return (
      <Box className={isCrew(design.format) ? classes.previewBox : classes.previewBoxLow}>
        <Box
          className={classes.visibilityIcon}
          style={{ opacity: overlay ? 1 : 0.5 }}
          onClick={e => setOverlay(!overlay)}
        >
          <VisibilityIcon />
        </Box>
        <DesignCanvas
          index={1}
          firebase={props.firebase.getFirebase()}
          classes={classes}
          format={design.format}
          profile={profile}
          colorSets={colorSets}
          neutrals={neutrals}
          layers={__layers}
          isFront={isFront}
          overlay={overlay}
        />
      </Box>
    )
  }

  const renderButtons = () => {
    return (
      <Box className={classes.designFooter}>
        <Button variant="contained" color="primary" className={classes.addButton} onClick={handleAddLayer}>
          Add Layer
        </Button>

        <LoadingButton
          loading={isSaving}
          className={classes.saveButton}
          variant="contained"
          onClick={handleSave}
          loadingPosition="start"
        >
          Save
        </LoadingButton>

        <Button
          variant="contained"
          color="primary"
          disabled={design.id == ""}
          className={classes.publishButton}
          onClick={handlePublish}
        >
          Publish
        </Button>
      </Box>
    )
  }

  const renderPublishDialog = () => {
    return (
      <Dialog onClose={handleCloseProductModal} aria-labelledby="product-dialog-title" open={openProductModal}>
        <DialogTitle id="product-dialog-title" onClose={handleCloseProductModal}>
          Confirmation
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>Are you sure you want to pubilsh this design?</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseProductModal} className={classes.saveButton}>
            Return
          </Button>
          <Button onClick={handlePublishProduct} className={classes.publishButton}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const __layers = stableSort(_.filter(layers, { removed: false }), getComparator("desc", "zindex"))
  const classes = useDesignModalStyles()
  const scroll = "paper"
  const _layer = _.find(__layers, { id: updateLayerId })

  return (
    <Dialog
      onClose={handleCloseDialog}
      aria-labelledby="revision-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"lg"}
      className="design-modal"
      scroll={scroll}
    >
      <DialogContent style={{ padding: 0 }}>
        <Box style={{ position: "relative", padding: "20px 5px" }}>
          <Box className={classes.deleteIcon} onClick={e => setOpenDeleteModal(true)}>
            <DeleteOutlineIcon />
          </Box>

          <Box style={{ position: "absolute", top: "0px", right: "0px" }}>
            <IconButton
              style={{ right: "22px" }}
              aria-label="close"
              className={classes.closeButton}
              onClick={handleCloseDialog}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              {renderPreview()}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box style={{ padding: "0px 80px 0px 0", height: "100%" }}>
                {renderInformation()}
                {renderControl()}
                {renderLayers()}
                {error.label_red && error.message && <p className="error-message">{error.message}</p>}
                {renderButtons()}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {renderRemoveDialog()}
        {renderPublishDialog()}

        <LayerModal
          key="layer_create"
          isCreate={true}
          template={design.id}
          open={openLayerModal}
          firebase={props.firebase.getFirebase()}
          handleAdd={handleNewLayer}
          handleClose={handleCloseLayerDialog}
        />

        {_layer && (
          <LayerModal
            key="layer_update"
            isCreate={false}
            template={design.id}
            open={!!_layer}
            firebase={props.firebase.getFirebase()}
            layer={_layer}
            handleUpdate={handleUpdate}
            handleDelete={handleDeleteLayer}
            handleClose={handleCloseUpdateLayerDialog}
          />
        )}

        {/*design.id !== "" && (
            <ProductModal
              key="product_modal"
              open={openProductModal}
              handleClose={handleCloseProductModal}
              handlePublish={handlePublishProduct}
              firebase={props.firebase.getFirebase()}
              design={design}
              colorSets={colorSets}
              profiles={profiles}
            />
            )*/}
      </DialogContent>
    </Dialog>
  )
}

const condition = authUser => !!authUser
export default withAuthorization(condition)(DesignModal)
