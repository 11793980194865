import {collections} from './defines';
import _  from "lodash";
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot
} from "firebase/firestore";

const createProduct = (db, product) => {
  return new Promise((resolve, reject) => {
    const newItem = { ...product };  
    newItem.createdAt = new Date();
  
    addDoc(collection(db, collections.PRODUCTS), newItem)  
      .then((docRef) => {
        resolve(docRef.id);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);        
      });
  });
}

const updateProduct = (db, docId, item) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, collections.PRODUCTS, docId);
    updateDoc(docRef, {...item})
      .then(() => {
        console.log("Document successfully updated!");
        resolve();
      })
      .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
          reject();
      });
  });
}

const deleteProduct = (db, item) => {
  return new Promise((resolve, reject) => {
    deleteDoc(doc(db, collections.PRODUCTS, item.id))
      .then(() => {
        resolve();
      })
  });  
}

const getProduct = (db, productId) => {
  return new Promise((resolve, reject) => {
    // Get any batch errors
    const docRef = doc(db, collections.PRODUCTS, productId);
    getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const data = {...docSnap.data()};
          resolve(
            {
              colorSet: data.colorSet,
              design: data.design,
              profile: data.profile,
              name: data.name,
              sku: data.sku,
              format: data.format
            }
          );
        } else {
          resolve(null)
        }
      })
  });
}

const getProducts = (db, callback) => {
  const q = query(collection(db, collections.PRODUCTS), orderBy('name'));

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    console.log('Products Changed...');
    let items = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      const item = {
        id: doc.id,
        name: data.name,
        sku: data.sku, 
        mockup: data.mockup?data.mockup:"",
        artFront: data.artFront?data.artFront:"",
        artBack: data.artBack?data.artBack:"",
        format: data.format,
        profile: data.profile,
        design: data.design,
        colorSet: data.colorSet,
        createdAt:data.createdAt,
        modifiedOn: data.modifiedOn?data.modifiedOn:data.createdAt,
        shopifyCreated: data.shopifyCreated,
        shopifyProduct: data.shopifyProduct?data.shopifyProduct.id:0
      };
      items.push(item);
    });
    callback(items);
  });
}


export { createProduct, updateProduct, deleteProduct, getProduct, getProducts };