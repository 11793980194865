import { withStyles  } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: '5px',
        width: '100%'
      },
      'label': {
        fontSize: '16px',
        fontWeight: '500',
        color: '#000000'
      }
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'rgba(0,0,0,0.04)',
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 10px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    }
}))(InputBase);
  
export default BootstrapInput;