import React, { useState, useEffect, createRef } from 'react';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InputBase from '@material-ui/core/InputBase';
import Input from '@material-ui/core/Input';
import shortid from "shortid";
import {createProfile, updateProfile} from '../Utils/profiles';
import { withAuthorization } from '../Session';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {getCollections} from '../Utils/collections';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Popover from '@material-ui/core/Popover';
import {ColorInput, ColorBox, ColorButton} from 'material-ui-color';
import LogoDialog from './logoDialog';
import { DEFAULT_PROFILE, DEFAULT_LOGO_INFO, LAYER_OPTIONS } from '../Utils/defines';
import {Logo, LogoUploader} from './logoUploader';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: '5px',
      width: '100%'
    },
    'label': {
      fontSize: '16px',
      fontWeight: '500',
      color: '#000000'
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 10px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  }
}))(InputBase);

const useHeaderStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    color: '#000000',
  },
  inputRoot: {
    width: '100%',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px',
    color: '#000000',
    background: 'rgba(0,0,0,0.04)',
    padding: '11px 15px',
    borderRadius: '4px'
  },
  inputLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000'
  },
  addButton: {
    background: '#3D3D3D',
    padding: '8px 32px',
    marginRight: '15px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial'
  },
  blueButton: {
    background: '#1F7CF2',
    padding: '8px 56px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial'
  },
  accordion: {
    border: "none", 
    boxShadow: "none", 
    margin: 0
  },
  accordionIcon: {
    marginRight: 0,
    marginLeft: "0",
    fontSize: "30px",
    color: "black",
  },
  modal_title: {
    fontSize: '32px', 
    margin: 0, 
    fontWeight: '500'
  },
  modal_label: {
    fontSize: '16px', 
    display: 'block',
    fontWeight: '500'
  },
  heading: {
    fontSize: '24px',
    fontWeight: '500',
    color: 'black',
    paddingLeft: "15px"
  },
  dropButton: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    width: '100%',
    background: '#3D3D3D',
    borderRadius: '6px',
    textTransform: 'Capitalize',
    height: '40px',
    padding: '0',
    marginTop: '30px'
  },
  colorLabelBox: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '30px'
  },
  colorLabel: {
    fontSize: '15px',
    fontWeight: 500,
    color: 'black',
    lineHeight: '1',
    letterSpacing: '-0.8px',    
  },
  colorBoxWrap: {
    padding: '10px 15px',
  },
  colorBox: {
    width: 60,
    height: 60,
    borderRadius: 5,
  },
  noColorBox: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    borderRadius: 5,
    border: 'solid 1px rgba(217, 223, 233, 0.4)',
    boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)',
    backgroundColor: 'transparent',
    backgroundImage: 'gradient(linear, 19.1% -7.9%, 81% 107.9%, color-stop(0, transparent), color-stop(.48, transparent), color-stop(.5, #000), color-stop(.52, transparent), color-stop(1, transparent))',
    backgroundImage: 'repeating-linear-gradient(135deg, transparent 0%, transparent 48%, #ff0000 50%, transparent 52%, transparent 100%)',
    '&:hover': {
      boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
      backgroundColor: '#d5d5d5'
    },
  },

  labelBox: {
    width: 'calc(100% - 80px)',
    paddingLeft: '12px',
    '& label': {
      display: 'none'
    },
    '& input': {
      height: '30px'
    }
  },
  colorFlex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  colorFlex2: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '40px',
    maxWidth: '475px'
  },
  colorFlexItem: {
    width: '20%'
  },
  colorFlexItem2: {
    width: '50%'
  },
  attrWrapper: {
    margin: '0 -12px'
  },
  logoWrapper: {
    flexGrow: 1, 
    bgcolor: 'background.paper', 
    display: 'flex', 
    height: 250, 
    width: '100%',
    border: '1px solid #ced4da',
    borderRadius: 5
  },
  logoTab: {
    color: 'black!important',
    fontFamily: 'Poppins!important',
    fontWeight: '500!important',
    textTransform: 'initial!important'
  },
  logoList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tagList: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    marginTop: '16px',
    '& li': {
      marginBottom: '10px'
    }
  },
  chip: {
    marginRight: '16px',
    borderRadius: '6px',
    background: '#3d3d3d',
    height: '40px',
    padding: '0 15px 0 15px'
  },
  chipLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF'
  },
  chipButton: {
    color: '#ffffff',
    opacity: '0.4',
    marginLeft: '15px',
    padding: '0',
    '&:hover': {
      opacity: '1',
    }
  },  
}));

/* Color Item */
function ColorItem(props) {
  const {classes, label, path, onChange} = props;

  const [color, setColor] = useState(props.color);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (onChange) {
      onChange(path, color);
    }
      
  }, [color]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeColor = (value) => {
    if (value == undefined)
      return;

    console.log('New Color: ', value);
    setColor(`#${value.hex}`);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box display="flex" className={classes.colorBoxWrap}>
      <Box className={classes.colorBox} onClick={handleClick}>
        {color?(
          <ColorButton color={color} size={60} />
        ):(
          <Box className={classes.noColorBox}></Box>
        )}        
      </Box>
      <Box className={classes.labelBox}>
        <Box className={classes.colorLabelBox}>
          <Typography className={classes.colorLabel}>{label}</Typography>
        </Box>        
        {/*color?<ColorInput defaultValue={color} format="hex" value={color} />:<ColorInput defaultValue={color} value={color} />*/}
        <Input placeholder="Color" defaultValue={color?color:'#'} value={color?color:'#'} onChange={e => setColor(e.target.value)} style={{width:'100%'}}/>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ColorBox defaultValue={color} onChange={handleChangeColor}/>
      </Popover>      
    </Box>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding:'10px 20px'}}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `media-tab-${index}`,
    'aria-controls': `media-tabpanel-${index}`,
  };
}

function ProfileModal(props) {
  const {handleClose, open, item, isCreate} = props;
  const [error, setError] = useState({message: '', label_red: false});
  const [profile, setProfile] = useState({...DEFAULT_PROFILE});
  const [loadCollection, setLoadCollection] = useState(true);
  const [selected, setSelected] = useState(null);
  const [collections, setCollections] = useState([]);  
  const [tab, setTab] = useState(0);

  const db = props.firebase.getdb();
  const storage = props.firebase.getstorage();

  useEffect(() => {
    getCollections(db, fetchCollectionData);
    if (isCreate)
      setProfile({...DEFAULT_PROFILE});
    else
      setProfile({...props.item});
    console.log(profile)
  }, [props.item]);  
  

  const fetchCollectionData = (items) => {
    console.log('Fetch Data');
    setCollections([...items]);
    setLoadCollection(false);    
  };

  const handleSave = () => {    

    if (!profile.name) {
      setError({...error, label_red: true, message: "Label is required!"});
    } else if (!profile.sku) {
      setError({...error, label_red: true, message: "SKU is required!"});
    } else if (!profile.collection) {
      setError({...error, label_red: true, message: "Collection is required!"});
    } else {
      if (isCreate) {
        createProfile(db, profile);
        setProfile({...DEFAULT_PROFILE});
        setError({...error, label_red: false, message: ""});
      } else {
        updateProfile(db, profile);
        setProfile({...props.item}); 
        setError({...error, label_red: false, message: ""});
      }
      handleClose();
    }    
  }

  const handleCloseDialog = (event) => {
    if (isCreate)
      setProfile({...DEFAULT_PROFILE});
    else
      setProfile({...props.item});  
    handleClose(); 
  }

  const handleCloseLogoDialog = (isPrimary) => {     
    setSelected(null);
  }

  const handleSaveLogo = (item) => {
    const logos = _.map(profile.logos, logo => {
      if (logo.id === item.id) {
        return {...logo, ...item};
      }
      return {...logo};
    });

    setProfile({...profile, logos})
  }

  const handleDeleteLogo = (id, type) => {
    let logos = _.filter(profile.logos, item=>item.id!==id);
    //delete logo 
    //set new preferred
    let noPreferred = _.filter(logos, {type:type, preferred:true}).length == 0;

    const newLogos = _.map(logos, (item) => {
      if (noPreferred && item.type == type) {
        noPreferred = false;
        return {...item, preferred:true};
      }
      return {...item};
    });
    setProfile({...profile, logos:newLogos});

  }

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleUploadLogo = (type, filename, width, height) => {
    console.log('Upload Complete ',filename);
    let logos = [...profile.logos];

    let logo = {...DEFAULT_LOGO_INFO};
    logo.id = shortid.generate();
    logo.width = width;
    logo.height = height;
    logo.x = width/2;
    logo.y = height/2;
    logo.path = filename;
    logo.preferred = _.filter(logos, {type:type}).length==0;
    logo.type = type;
    logo.name = `${logos.length + 1}`;
    logos.push(logo);
    //setPrimaryLogos([...logos]);
    setProfile({...profile, logos});
    setSelected(logo);
  };

  const handleClickLogo = (id, type) => {
    const item = _.find(profile.logos, {id});
    setSelected({...item});
  }

  const handleClickLogoIcon = (id, type) => {
    console.log('Icon Click  ', id);
    const logos = _.map(profile.logos, item => {
      if (item.id == id) {
        return {...item, preferred:true};
      } else if (item.type == type) {
        return {...item, preferred:false};
      }
      return {...item};
    });

    setProfile({...profile, logos});
  }

  const renderLogos = (type) => {
    const logoKey = type;
    const preferredLogo = _.find(profile.logos, {type:type, preferred:true});
    const logos = _.filter(profile.logos, {type:type, preferred:false});
    return (
      <Box className={classes.logoList}>
        {preferredLogo && (
        <Logo 
          key={`${logoKey}Logo_${preferredLogo.id}`} 
          storage={storage} 
          icon={true}
          active={true}
          data={preferredLogo} 
          onClick={handleClickLogo} />
        )}

        {
          _.map(logos, (logo) => 
            <Logo 
              key={`${logoKey}Logo_${logo.id}`} 
              storage={storage} 
              icon={true}
              active={false}
              data={logo} 
              onClick={handleClickLogo}
              onClickIcon={handleClickLogoIcon}
            />)
        }
        <LogoUploader key={`${logoKey}_Uploader`} type={type} storage={storage} onComplete={handleUploadLogo} />
      </Box>
    );
  }

  const renderMedia = () => {
    let tags = [];
    if (selectedCollection && selectedCollection.tags.length>0) {
      tags = [...selectedCollection.tags];
    }

    return (
      <Box className={classes.logoWrapper}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tab}
          textColor="primary"
          onChange={handleChangeTab}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab label="Primary Logo" className={classes.logoTab} {...a11yProps(0)} />
          <Tab label="Secondary Logo" className={classes.logoTab} {...a11yProps(1)} />
          {_.map(tags, (item, index) => {
            return (
              <Tab label={item} className={classes.logoTab} {...a11yProps(2+index)} />
            );
          })}

        </Tabs>
        <TabPanel value={tab} index={0}>
          {renderLogos("primary_logo")}
        </TabPanel>
        <TabPanel value={tab} index={1}>
          {renderLogos("secondary_logo")}
        </TabPanel>
        {_.map(tags, (item, index) => {
            return (
              <TabPanel value={tab} index={2+index}>{renderLogos(item)}</TabPanel>
            );
        })}
      </Box>
    );
  }

  const handleChangeColor = (path, value) => {
    let colors = {...profile.colors};

    colors[path] = value;

    setProfile({...profile, colors});
  }

  const renderColors = () => {
    const mainColors = _.filter(LAYER_OPTIONS, {swatch:'property'});

    return (
    <Box className={classes.attrWrapper}>
      <Box className={classes.colorFlex}>
      {_.map(mainColors, item =>
        <Box className={classes.colorFlexItem}>
          <ColorItem 
            classes={classes} 
            label={item.name} 
            path={item.value}
            color={profile.colors[item.value]} 
            onChange={handleChangeColor}
          />
        </Box>
      )}
      </Box>
    </Box>
    );
  }

  const renderTags = () => {
    const tags = [...selectedCollection.tags];
    return (
      <Box>
      {_.map(tags, (item, index) => {
        return (
          <Tab label={item} className={classes.logoTab} {...a11yProps(2+index)} />
        );
      })}
      </Box>
    );
  }

  const classes = useHeaderStyles();
  const scroll = 'paper';
  const selectedCollection = _.find(collections, {id:profile.collection});

  return (
    <Dialog onClose={handleCloseDialog} 
        aria-labelledby="revision-dialog-title" 
        open={open}         
        disableEscapeKeyDown
        fullWidth={true} 
        maxWidth={"lg"} 
        className="profile-modal"
        scroll={scroll}
    >
        <DialogTitle onClose={handleCloseDialog} style={{padding: '40px 40px 10px', position: 'relative'}}>
          <IconButton style={{right: '22px'}}  aria-label="close" className={classes.closeButton} onClick={handleCloseDialog}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent style={{padding: 0, margin: '0 40px', borderTop: 'none'}} dividers>
          <p className={classes.modal_title}>{props.modal_title ? props.modal_title : profile.name }</p>   
          <Box style={{padding: '40px 12px 20px'}}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={5}>
                <label>Admin Label</label>
                <BootstrapInput 
                  label="Admin Label"
                  type="text"                                         
                  value={profile.name}
                  placeholder="Enter Value"
                  onChange={(e) => setProfile({...profile, name: e.target.value})}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <label>SKU</label>
                <BootstrapInput 
                  label="SKU"
                  type="text"                                         
                  value={profile.sku}
                  placeholder="Enter Value"
                  onChange={(e) => setProfile({...profile, sku: e.target.value})}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <label>Collection</label>
                <Select                      
                  style={{width: '100%'}}
                  input={<BootstrapInput />}
                  value={profile.collection}
                  onChange={(e) => setProfile({...profile, collection: e.target.value})} 
                >     
                  <MenuItem value="">-</MenuItem>
                  {collections.map(function(collection, index){
                    return <MenuItem value={collection.id}>{collection.name}</MenuItem>
                  })} 
                </Select>
              </Grid>
            </Grid>

            <Accordion className={classes.accordion} defaultExpanded={true} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.accordionIcon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{flexDirection: 'row-reverse'}}
              >
                <Typography className={classes.heading}>Text Attributes</Typography>
              </AccordionSummary>
              <AccordionDetails style={{padding: 0}}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={5}>
                    <label>Primary Name</label>
                    <BootstrapInput 
                      label="Primary Name"
                      type="text"                                         
                      value={profile.primary_name}
                      placeholder="Enter Value"
                      onChange={(e) => setProfile({...profile, primary_name: e.target.value})}
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={5}>
                    <label>Secondary Name</label>
                    <BootstrapInput 
                      label="Secondary Name"
                      type="text"                                         
                      value={profile.secondary_name}
                      placeholder="Enter Value"
                      onChange={(e) => setProfile({...profile, secondary_name: e.target.value})}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion} defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.accordionIcon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{flexDirection: 'row-reverse'}}
              >
                <Typography className={classes.heading}>Profile Media</Typography>
              </AccordionSummary>
              <AccordionDetails style={{padding: 0}}>
                {renderMedia()}
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion} defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.accordionIcon} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{flexDirection: 'row-reverse'}}
              >
                <Typography className={classes.heading}>Color Attributes</Typography>
              </AccordionSummary>
              <AccordionDetails style={{padding: 0}}>
                {renderColors()}
              </AccordionDetails>
            </Accordion>
          </Box>

          <LogoDialog 
            open={selected!=null} 
            data={selected}
            onSave={handleSaveLogo}
            onDelete={handleDeleteLogo}
            onClose={handleCloseLogoDialog} 
          />  

        </DialogContent>

        <DialogActions  style={{padding: '40px', justifyContent: 'flex-start'}}>
          <Button variant="contained" color="primary" className={classes.blueButton} onClick={handleSave}>Save</Button>
          {error.label_red && error.message && <p className="error-message">{error.message}</p>}
        </DialogActions>
    </Dialog>
  );
}


const condition = authUser => !!authUser;
export default withAuthorization(condition)(ProfileModal);
