import Handlebars from "handlebars";
import {ref, getDownloadURL} from "firebase/storage";

const MAX_BATCH_CHARS = 41;

const formatDate = (_time) => {
    var date = _time.toDate();    
    const year = date.getFullYear() - 2000;
    var str = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + year;
    return str;
}

const formatTime = (_time) => {
    let date = _time.toDate();    
    let hrs = date.getHours(), mins = date.getMinutes();

    hrs = hrs>9?hrs:'0'+hrs;
    mins = mins>9?mins:'0'+mins;
    
    let str = hrs + ':' + mins;
    return str;
}

const formatBytes = (bytes, decimals = 2) => {
    if (!bytes || bytes === 0) return {value: 0, unit: 'B'};

    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return {value: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), unit: sizes[i]};
}

const parseFileName = (path) => {
    let startPos = 0, items = [];
    for(let i=0;i<path.length;i++) {
        let c = path[i];
        if (c === '-' && startPos !== i) {
            items.push(path.substring(startPos, i));
            startPos = i+1;
        }
    }

    const sku = `${items[0]}-${items[1]}-${items[2]}-${items[3]}-LXL`;
    const name = `${items[0]} ${items[1]} ${items[2]} ${items[3]}`;
    return {sku, name};
}

const capitalizeString = (str) => {
    const str1 = str.toLowerCase();
    const str2 = str1.charAt(0).toUpperCase() + str1.slice(1);
    return str2;
}

const composeProductName = (collection, profile, design, project) => {
    let sku = `${collection.sku===""?"-":collection.sku}-${profile.sku===""?"-":profile.sku}-${design.sku===""?"-":design.sku}-${project.sku===""?"-":project.sku}-LXL`;
    if (isLow(design.format)) {
       sku = `${collection.sku===""?"-":collection.sku}-${profile.sku===""?"-":profile.sku}-${design.sku===""?"-":design.sku}-${project.sku===""?"-":project.sku}-LOW-LXL`;
    }

    //const name = `${collection} ${profile} ${design} ${project}`;
    //const name = `${profile} - ${capitalizeString(design)} (${capitalizeString(project)})`;
    const name = `${profile.name} - ${design.name} (${project.name})`;
    return {sku, name};
}

const validateBatchName = (batchName) => {
    var regex = /^[A-Za-z0-9., _-]+$/
    if (batchName === "") {
        console.log("Blank Batch Name");
        return "Blank Name";
    } else if ( batchName.length > MAX_BATCH_CHARS) {
        return "40 Characters Max";
    }
    //Validate TextBox value against the Regex.
    var isValid = regex.test(batchName);
    if (isValid === false) {
        return  "Contains Special Character";
    }
    return "Success";
}

const existFile = (storage, path) => {
    return new Promise((resolve, reject) => {
        getDownloadURL(ref(storage, path))
            .then(url => {
                resolve(true);
            })
            .catch(err => {
                resolve(false);
            })
    });
}

const isCrew = (format) => format==="Crew";
const isLow = (format) => format==="Low";

export {
    formatDate, 
    formatTime, 
    formatBytes, 
    parseFileName, 
    validateBatchName, 
    composeProductName,
    existFile,
    isCrew,
    isLow,
};