import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { withAuthorization } from '../Session';
import Layout from '../Page';
import {deletePattern, getPatterns} from '../Utils/patterns';
import PatternModal from './patternModal';


const headCells = [
  { id: 'name', numeric: false, align: 'left', disablePadding: false, label: 'NAME', width: 'auto' },
  { id: 'edit', numeric: true, align: 'left', disablePadding: false, label: '', width: '120px'  },
  { id: 'delete', numeric: true, align: 'left', disablePadding: false, label: '', width: '100px' },  
];

function EnhancedTableHead(props) {
  const { classes } = props;

  return (
    <TableHead>
      <TableRow className={classes.headRow}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'default'}             
            style={{width: headCell.width}}       
          >
            <Typography style={{fontSize: '14px', fontWeight: '500', letterSpacing: '1.5px'}}>
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    padding: '9px 32px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#ffffff',
    textTransform: 'initial',
    marginRight: '16px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  headRow: {
    background: '#d9dfe9'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableCell: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: '#000000'
  },
  tableCellError: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: 'red'
  },
  tableRow: {
    backgroundColor: 'rgba(242, 244, 246, 0.4)'
  },  
  tableRow2: {
    backgroundColor: '#FFFFFF'
  },
  tableRowError: {
    backgroundColor: 'rgba(255, 0, 0, 0.1)'
  },
  selectedTableRow: {
    backgroundColor: '#F1F7FE!important',
  },
  pagination: {
    marginTop: '60px',
    '& ul': {
      justifyContent: 'center'
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  pageText: {
    color: '#000000',
    letterSpacing: '1.5px',
    fontSize: '14px',
    fontWeight: '500'
  },
  disabledButton: {
    backgroundColor: '#ccc!important',
    color: 'gray !important'
  },
  emptyText: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000'
  },
  inputQty: {
    '& input': {
      borderRadius: '6px',
      border: '1px solid rgba(0,0,0,0.08)',
      padding: '10px 12px'
    }
  },
  pageHeading: {
    
  },
  sectionHeading: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    marginBottom: '15px'
  },
  searchText: {
    fontSize: '14px',
    fontWeight: '500',
    color: 'rgba(0,0,0, 0.56)',
    textTransform: 'uppercase'
  },
  updateButton: {
    color: '#FFFFFF',
    textTransform: 'initial',
    width: '110px'
  },
}));

function Patterns(props) {  
  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);  
  const [openModal, setOpenModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const db = props.firebase.getdb();

  useEffect(() => {
    getPatterns(db, fetchData);
  }, []);

  
  const fetchData = (items) => {
    console.log('Fetch Data');
    setData([...items]);
    setLoading(false);
  };

  const handleCloseDialog = () => {
    setOpenModal(false);
  }

  const handleCloseCreateDialog = () => {
    setOpenCreateModal(false);
  }

  const handleClick = (event, name) => {    
    setSelected(name);
  };

  const handelEdit = (event, name) => {
    setSelected(name);
    setOpenModal(true);
  }

  const isSelected = (name) => selected === name;

  const handleAdd = () => {
    setOpenCreateModal(true);
  }

  const handleCloseRemoveDialog = () => {
    setOpenDeleteModal(false);
  }

  const handleDelete = (event) => {
    const _selected = _.find(data, row => isSelected(row.name));  
    setOpenDeleteModal(false);
    setSelected("");
    deletePattern(db, _selected);    
  }

  const renderRemoveDialog = () => {
    return (
      <Dialog onClose={handleCloseRemoveDialog} aria-labelledby="revision-dialog-title" open={openDeleteModal}>
        <DialogTitle id="revision-dialog-title" onClose={handleCloseRemoveDialog}>
          Confirmation
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Do you want to remove <strong>{selected}</strong> ?
          </Typography>          
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseRemoveDialog} color="primary">
            No
          </Button>
          <Button onClick={handleDelete} color="primary">Yes</Button>
        </DialogActions>
      </Dialog>
    );
  }

  const classes = useStyles();
  const selectedItem = _.find(data, row => isSelected(row.name));  
  
  return (
    <Layout page="patterns" >
      <div className="pageHeader">
        <div className={classes.pageHeading}>
          <Typography variant="h1">Patterns</Typography> 
          <Typography className={classes.searchText}><strong>{data.length}</strong> Total Patterns</Typography>   
        </div>             
      </div>

      <div className='pageToolbar'>
        <div className={classes.buttonBox}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={handleAdd}
            style={{background: '#1f7cf2'}}          
          >
            Add Pattern
          </Button>
        </div>
      </div>

      <TableContainer style={{marginTop: '30px', marginBottom: '50px'}}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"            
        >
          <EnhancedTableHead classes={classes} />
          <TableBody>
            {data
              .map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;
                const keyId = `key-${row.sku}-${index}`;
                const odd = index%2 === 0;
                return (
                  <TableRow
                    hover                                        
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={keyId}                      
                    classes={{
                      root: odd?classes.tableRow:classes.tableRow2,
                      selected: classes.selectedTableRow, 
                    }}
                    selected={isItemSelected} 
                    onClick={(event) => handleClick(event, row.name)}                       
                  >
                    <TableCell align="left" className={classes.tableCell}>{row.name}</TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <Button
                        variant="contained"
                        className={classes.updateButton}
                        style={{background: '#1F7CF2'}} 
                        onClick={() => handelEdit(row.name)}         
                      >
                        Edit
                      </Button>
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      <Button onClick={() => setOpenDeleteModal(true)}>
                        <DeleteOutlineIcon />
                      </Button>

                    </TableCell>
                  </TableRow>                  
                );
              })}

              {loading && (
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    <CircularProgress />  
                  </TableCell>
                </TableRow>
              )}

              {loading===false && data.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={3} className={classes.tableCell}>
                    No Pattern
                  </TableCell>

                </TableRow>
              )}
            
          </TableBody>
        </Table>
      </TableContainer> 
      
      {selected && (<PatternModal open={openModal} handleClose={handleCloseDialog} item={selectedItem} />)}    

      <PatternModal isCreate open={openCreateModal} handleClose={handleCloseCreateDialog} item={null} />  

      {renderRemoveDialog()}
    </Layout>
  );
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(Patterns);

