import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { styled } from "@mui/material/styles"

import { withAuthorization } from '../Session';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button"
import Typography from "@mui/material/Typography"
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import VisibilityOffSharpIcon from '@material-ui/icons/VisibilityOffSharp';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { ColorButton } from "material-ui-color"

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: '5px',
      width: '100%'
    },
    'label': {
      fontSize: '14px',
      fontWeight: '500',
      color: '#000000'
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'rgba(0,0,0,0.04)',
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '8px 8px 8px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },

}))(InputBase);

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#333333",
      color: "ffffff",
      fontSize: "14px",
      padding: "5px 10px",
      borderRadius: "6px",
    },
    [`& .${tooltipClasses.arrow}`]: {
      fontSize: 22,
      width: 22,
      "&::before": {
        backgroundColor: "#333333",
        boxSizing: "border-box",
      },
    },
  })
)

const useStyles = makeStyles((theme) => ({
  layer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    position: "relative",
    padding: "5px 10px",
    marginTop: "15px",
    height: "40px",
    borderRadius: "5px",
    boxSizing: "border-box",
    '&:hover svg': {
      opacity: 1
    }
  },
  view: {
    width: "20px",
    opacity: 0.3
  },
  title: {
    padding: "0 10px",
    margin: 0,
    fontSize: "14px",
    fontWeight: "500"
  },
  position: {
    position: "absolute",
    right: "10px",
    width: "24px",
    transform: "translateY(-50%)",
    top: "50%",
    cursor: "pointer"
  },
  edit: {
    position: "absolute",
    right: "40px",
    width: "16px",
    transform: "translateY(-50%)",
    top: "50%",
    cursor: "pointer"
  },
  label: {
    fontSize: '13px'
  },
  colorSetBox: {
    display: "flex",
    height: "100%",
    padding: '20px 0 0'
  },
  colorSetBoxMain: {
    width: "110px",
    borderRight: "1px solid #ccc",
  },
  colorSetBoxSecond: {
    width: "100%",
    maxWidth: '360px',
    display: 'flex',
    flexWrap: 'wrap'
  },
  colorBoxLine: {
    display: "flex",
  },
  colorTypeText: {
    textTransform: "initial",
    fontSize: "15px!important",
  },
  colorItem: {
    padding: "2px",
    boxSizing: "border-box",
    border: "2px solid transparent",
  },
  colorActiveItem: {
    padding: "2px",
    border: "2px solid #ccc",
    boxSizing: "border-box",
  },
}));

function LayerComponent(props) {  
  const {layers, onUpdate, isFront, onOpenModal} = props;
  const [layer, setLayer] = useState({});
  const [locked, setLocked] = useState(true);
  const [colorSet, setColorSet] = useState(null)
  const classes = useStyles();

  useEffect(() => {
    let newLayer = {...props.layer};
    newLayer.pos = {...props.layer.pos};
    setLayer(newLayer);    
  }, [props.layer]);

  useEffect(() => {
    if (props.colorSet) {
      setColorSet({...props.colorSet, items:{...props.colorSet.items}});
    } else {
      setColorSet(null);
    }
  }, [props.colorSet]);

  const toggleLayer = () => {
    let _layer = {...layer};
    if (isFront)
      _layer.visible = !_layer.visible;
    else 
      _layer.visibleBack = !_layer.visibleBack;
    setLayer(_layer);
    if (onUpdate)
      onUpdate(_layer);
  }
  const openModalFunc = () => {
    if (onOpenModal) {
      onOpenModal(layer.id);
    }    
  }

  const handleChange = (event) => {
    let { name, value, type } = event.target;
    if (type === "number")
      value = parseFloat(value);

    // Handle face slots if num changed
    const _layer = {...layer};
    _layer[name] = value;
    setLayer(_layer);
    if (onUpdate)
      onUpdate(_layer);
  }

  const handleLocked = () => {
    setLocked(!locked);
  }

  const handleExpland = () => {
    const _layer = {...layer};
    _layer.expanded = !_layer.expanded;
    setLayer(_layer);
    if (onUpdate)
      onUpdate(_layer); 
  }

  const handleUpdateColorSet = (colorSetId, id, color) => {
    if (id && color && onUpdate) {
      const newLayer = { ...layer, swatch: "colorsets", custom1: colorSetId, custom2: id }
      //setLayer(newLayer);
      onUpdate(newLayer)
    }
  }

  const renderColorSetValue = (colorSetId, id, color) => {
    const active = layer.custom2 === id
    return (
      <HtmlTooltip
        placement="top-start"
        title={
          <React.Fragment>
            <Typography color="inherit">{color ? color : "No Color Code"}</Typography>
          </React.Fragment>
        }
        arrow
        enterDelay={1000}
        enterNextDelay={1000}
      >
        <Box
          className={active ? classes.colorActiveItem : classes.colorItem}
          onClick={e => handleUpdateColorSet(colorSetId, id, color)}
        >
          {color ? <ColorButton color={color} size={40} /> : <Box className={classes.noColorBox}></Box>}
        </Box>
      </HtmlTooltip>
    )
  }

  const renderColorSets = () => {    
    return (
      <Box className={classes.colorSetBox}>
        <Box className={classes.colorSetBoxSecond}>
          
          {
            _.map(colorSet.items, item => {      
              console.log(`Item Id:${item.id}, Item Value:${item.value}`)      
              return renderColorSetValue(colorSet.id, item.id, item.value);
            })
          }
          
        </Box>       
      </Box>
    )
  }

  const width = layer.pos?layer.pos.width:0;
  const height = layer.pos?layer.pos.height:0;

  const visible = isFront?layer.visible:layer.visibleBack;
  return (
    <Box>
      <div className={classes.layer}>
        {visible ? <VisibilitySharpIcon className={classes.view} onClick={toggleLayer}/> : <VisibilityOffSharpIcon className={classes.view} onClick={toggleLayer}/>}      
        <p className={classes.title}>{layer.name}</p>

        <EditSharpIcon className={classes.edit} onClick={() => openModalFunc()} />
        {layer.expanded?(
          <ArrowDropUpIcon className={classes.position} onClick={() => handleExpland()} />
        ):(
          <ArrowDropDownIcon className={classes.position} onClick={() => handleExpland()} />
        )}
      </div>

      {layer.expanded && (
      <Box style={{padding: '15px 0 40px'}}>        
        <Grid container spacing={1}>
          <Grid item xs={5} >            
            <InputLabel className={classes.label}>Layer Association</InputLabel>
            <Select                      
              style={{width: '100%'}}
              name="association"
              input={<BootstrapInput />}   
              value={layer.association}
              onChange={handleChange}          
            >  
              <MenuItem value="">None</MenuItem> 
              {_.map(layers, item => {
                return (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                )
              })}
            </Select >  
          </Grid>
        </Grid>
        {colorSet && renderColorSets()}
      </Box>
      )}

    </Box>
  );
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(LayerComponent);

