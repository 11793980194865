import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { Button, AppBar, Avatar} from "@material-ui/core";
import logo from "../../images/Rock-Em-Logo.svg";
import IconButton from '@material-ui/core/IconButton';
import BatteryCharging20Icon from '@material-ui/icons/BatteryCharging20';
import BatteryCharging80Icon from '@material-ui/icons/BatteryCharging80';
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import {CropOriginal, Loyalty, Edit, ShowChart, AccessTime }  from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  mainLogo: {
    marginRight: '60px'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: '0px'
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: '10px 16px',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  navLink: {
    transition: 'all 200ms',
    fontSize: '16px',
    lineHeight: 1.25,
    color: 'white',
    textDecoration: 'none',
    textTransform: 'initial',
    padding: '30px 0px',
    borderBottom: '4px solid transparent',
    margin: '0 24px',
    opacity: '0.8',
    display: 'inline-block',
    cursor: 'Pointer',
    '&:hover': {
      borderBottom: '4px solid #1f7cf2',
      opacity: '1',
    },
    '&.current': {
      borderBottom: '4px solid #1f7cf2',
      opacity: '1',
    }
  },
  navSubLink: {
    transition: 'all 200ms',
    textDecoration: 'none',
    textTransform: 'initial',
    color: 'white',
    margin: '0 20px',
    '&:hover': {
      borderRadius: '8px',
      backgroundColor: 'rgba(242, 244, 246, 0.12)',
      '& span': {
        opacity: '1',
      }
    },
    '&.current': {
      borderRadius: '8px',
      backgroundColor: 'rgba(242, 244, 246, 0.12)',
      '& span': {
        opacity: '1',
      }
    },
    '& button': {
      padding: '12px 20px',      
    },
    '& span': {
      opacity: '0.8',
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: 1.25,
    },
    '& svg': {
      marginRight: '10px'
    }
  },
  primaryMenu: {
    padding: '0px 0px',
    background: "#222222"
  },
  secondaryMenu: {
    padding: '18px 0px',
    background: '#3d3d3d'
  },
  toolBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

function Navigation(props) {
  const classes = useStyles();
  const page = props.page;

  let isSecondaryMenuOpen = false, isSecondaryDesignMenuOpen = false;
  if (page === "profiles" || page === "collections" || page === "colorsets" || page === "patterns" || page === "neutrals") {    
    isSecondaryMenuOpen = true;
  } 

  if (page === "designs" || page === "published") {
    isSecondaryDesignMenuOpen = true;
  }

  let isProfilesPage  = false;
  if (page === "profiles") {
    isProfilesPage  = true;
  }

  let isDesignPage = page === "designs";
  let isColorsetsPage = page === "colorsets";  

  const renderSecondaryMenu = (
    <div className={classes.secondaryMenu}>
      <div className="Container">
        <div className={classes.toolBar}>     
          {/*
          <NavLink to={ROUTES.PROFILES} className={`${classes.navSubLink} ${isProfilesPage?"current":""}`}>
            <IconButton
              aria-label="Profiles"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <CropOriginal />Profiles
            </IconButton>
          </NavLink>
          
  
          <NavLink to={ROUTES.COLLECTIONS} className={classes.navSubLink} activeClassName="current">
            <IconButton
              aria-label="Partners"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <Loyalty />Partners
            </IconButton>
          </NavLink>
          */}
          <NavLink to={ROUTES.COLORSETS} className={`${classes.navSubLink} ${isColorsetsPage?"current":""}`}>
            <IconButton
              aria-label="Color Sets"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <Edit />Color Sets
            </IconButton>
          </NavLink>
          <NavLink to={ROUTES.PATTERNS} className={classes.navSubLink} activeClassName="current">
            <IconButton
              aria-label="Patterns"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <ShowChart />Patterns
            </IconButton>
          </NavLink>     
          {/*
          <NavLink to={ROUTES.NEUTRALS} className={classes.navSubLink} activeClassName="current">
            <IconButton
              aria-label="Neutrals"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <InvertColorsIcon />Neutrals
            </IconButton>
          </NavLink>   
          */}     
       
        </div>
      </div>
    </div>
  );

  const renderSecondaryDesignMenu = (
    <div className={classes.secondaryMenu}>
      <div className="Container">
        <div className={classes.toolBar}>     
          <NavLink to={ROUTES.DESIGNS} className={`${classes.navSubLink} ${isDesignPage?"current":""}`}>
            <IconButton
              aria-label="Color Sets"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <Edit />Drafts
            </IconButton>
          </NavLink>
          <NavLink to={ROUTES.PUBLISHED} className={classes.navSubLink} activeClassName="current">
            <IconButton
              aria-label="Patterns"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <ShowChart />Live
            </IconButton>
          </NavLink>
        </div>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <div className={classes.primaryMenu}>
          <div className="Container">
            <div className={classes.toolBar}>           
              <img src={logo} alt="Kitty Katty!" className={classes.mainLogo} />
              
              <div className="main-menu">
                <NavLink to={ROUTES.COLORSETS} className={`${classes.navLink} ${isSecondaryMenuOpen?"current":""}`} >Profiles</NavLink>
                <NavLink to={ROUTES.DESIGNS} className={classes.navLink} exact activeClassName="current" >Designs</NavLink>
                {/*<NavLink to={ROUTES.PRODUCTS} className={classes.navLink} exact activeClassName="current" >Published</NavLink>*/}                
              </div>                
              
              <div className={classes.grow} />

              <SignOutButton />
            </div>
          </div>
        </div>

        {isSecondaryMenuOpen && renderSecondaryMenu}

        {isSecondaryDesignMenuOpen && renderSecondaryDesignMenu}

      </AppBar>      
    </div>
  );
}

export default Navigation;
