import React, { useState, useEffect, createRef } from 'react';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Popover from '@material-ui/core/Popover';
import {ColorInput, ColorBox, ColorButton} from 'material-ui-color';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import shortid from "shortid";
import { createPattern, updatePattern } from '../Utils/patterns';
import { DEFAULT_PATTERN } from '../Utils/defines';
import { withAuthorization } from '../Session';
import { Divider } from '@material-ui/core';
import {PatternItem} from './patternItem';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: '15px',
      width: '100%'
    },
    'label': {
      fontSize: '16px',
      fontWeight: '500',
      color: '#000000'
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 10px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },

}))(InputBase);

const useHeaderStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    color: '#000000',
  },
  plusButton: {

  },
  inputRoot: {
    width: '100%',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px',
    color: '#000000',
    background: 'rgba(0,0,0,0.04)',
    padding: '11px 15px',
    borderRadius: '4px'
  },
  inputLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000'
  },
  addButton: {
    background: '#3D3D3D',
    padding: '8px 32px',
    marginRight: '15px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial'
  },
  blueButton: {
    background: '#1F7CF2',
    padding: '8px 56px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial'
  },
  colorsWrap: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px"
  },
  colorsWrapItem: {
    width: "15%"
  },
  colorFlexItem: {
    width: "15%"
  },
  attrHeading: {
    fontSize: '22px',
    fontWeight: '500',
    color: '#000000'
  },
  labelBox: {
    width: 'calc(100% - 80px)',
    paddingLeft: '12px',
    '& label': {
      display: 'none'
    },
    '& input': {
      height: '30px'
    }
  },  

}));

function PatternModal(props) {
  const {handleClose, open, item, isCreate} = props;
  const [error, setError] = useState({message: '', label_red: false});
  const [pattern, setPattern] = useState({...DEFAULT_PATTERN});
  const db = props.firebase.getdb();
  const storage = props.firebase.getstorage();

  useEffect(() => {
    if (open) {
        if (isCreate) {
          setPattern({...DEFAULT_PATTERN});
        } else {
          setPattern({...props.item});
        }
    }

  }, [open]);

  const handleSave = () => {
    if (!pattern.name) {
        setError({...error, label_red: true, message: "Name is required!"});
      } else {
        if (isCreate) {
          createPattern(db, pattern);      
        } else {
          updatePattern(db, pattern);      
        }
        setError({...error, label_red: false, message: ""});
        handleClose();
      }
  }

  const handleCloseDialog = (event, reason) => {
    if (reason === 'backdropClick') {
        return;
    }
    handleClose(); 
  }

  const handleChange = (event) => {
    let { name, value} = event.target;
    setPattern({...pattern, [name]:value});
  }

  const handleAddPatternItem = (line) => {
    let items = [...pattern.items];
    const id = shortid.generate(), width=0, height=0;
    let item = {id, value: "", line, width, height};
    items.push(item);
    setPattern({...pattern, items});
  }

  const handleCompleteUpload = (itemId, path, width, height) => {
    const items = _.map(pattern.items, item => {
        if (item.id === itemId)
            return {...item, value:path, width, height};
        return {...item};
    })
    setPattern({...pattern, items});
  }

  const renderColorsLine = (index, items) => {
    return (
        <Box className={classes.colorsWrap}>
            {_.map(items, (item, index) =>
                <Box className={classes.colorFlexItem}>
                    <PatternItem 
                      storage={storage} 
                      index={item.id}
                      classes={classes}
                      pattern={item.value} 
                      onChange={handleCompleteUpload}
                    />
                </Box>
            )}
            <Box className={classes.colorFlexItem}>
                <IconButton aria-label="close" className={classes.plusButton} onClick={e => handleAddPatternItem(index)}>
                    <AddCircleOutlineIcon />
                </IconButton>
            </Box>
        </Box>
    )
  }

  const classes = useHeaderStyles();
  const scroll = 'paper';

  let lastIndex = 0;
  _.each(pattern.items, item => {
    if (lastIndex <= item.line)
        lastIndex = item.line + 1;
  });

  let lines = [];
  for(let i=0;i<lastIndex;i++) {
    const colors = _.filter(pattern.items, {line:i});
    lines.push(renderColorsLine(i, colors));
  }

  return (
    <Dialog 
        onClose={handleCloseDialog} 
        aria-labelledby="pattern-dialog-title" 
        open={open} 
        fullWidth={true} 
        maxWidth={"lg"} 
        scroll={scroll}
    >
        <DialogTitle onClose={handleCloseDialog} style={{padding: '40px 40px 30px', position: 'relative'}}>
          <Typography variant="h1">{isCreate?"New Pattern":"Update Pattern"}</Typography>   
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{padding: '0 40px'}} dividers>
            <Box style={{padding: '30px 0'}}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <InputLabel className={classes.inputLabel}>Pattern Name</InputLabel>
                  <BootstrapInput name="name" value={pattern.name} onChange={handleChange} />
                </Grid>
              </Grid>
            </Box>

            {pattern && (
            <Box style={{padding: '20px 0'}}>
                <Typography className={classes.attrHeading}>Pattern Attributes</Typography>
                {lines}
            </Box>
            )}

            <IconButton aria-label="close" className={classes.plusButton} onClick={e => handleAddPatternItem(lastIndex)}>
                <AddCircleOutlineIcon />
            </IconButton>
        </DialogContent>

        <DialogActions  style={{padding: '40px', justifyContent: 'flex-start'}}>
          <Button variant="contained" color="primary" className={classes.blueButton} onClick={handleSave}>Save</Button>
          {error.label_red && error.message && <p className="error-message">{error.message}</p>}
        </DialogActions>
    </Dialog>
  );
}


const condition = authUser => !!authUser;
export default withAuthorization(condition)(PatternModal);
