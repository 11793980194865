import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InputBase from '@material-ui/core/InputBase';
import { withAuthorization } from '../Session';
import { DEFAULT_LOGO_INFO } from '../Utils/defines';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Divider from '@material-ui/core/Divider';
import { StoragePath } from '../Utils/defines';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: '10px',
      width: '100%'
    },
    'label': {
      fontSize: '16px',
      fontWeight: '500',
      color: '#000000'
    }
  },
  input: {
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 10px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  }
}))(InputBase);

const useHeaderStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    color: '#000000',
  },
  deleteButton: {
    position: 'absolute',
    left: '10px',
    bottom: '10px',
    color: '#000000',
  },  
  inputRoot: {
    width: '100%',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px',
    color: '#000000',
    background: 'rgba(0,0,0,0.04)',
    padding: '11px 15px',
    borderRadius: '4px'
  },
  inputLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000'
  },
  blueButton: {
    background: '#1F7CF2',
    padding: '8px 56px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial'
  },
  modal_title: {
    fontSize: '32px', 
    margin: 0, 
    fontWeight: '500',
    textTransform: 'capitalize'
  },
  modal_subtitle: {
    fontSize: '24px', 
    margin: 0, 
    fontWeight: '500'
  },
  modal_label: {
    fontSize: '16px', 
    display: 'block',
    fontWeight: '500'
  },
  formRow: {
      padding: '10px 0'
  },
  modal_input: {

  },
  revertLink: {
    cursor: 'pointer'
  },
  mainPane: {
    display: 'flex',    
    padding: '30px 10px 40px'
  },
  leftPane:{
    width: '420px'
  },
  rightPane: {
    width: 'calc(100% - 500px)',
    padding: '10px 30px'
  }
}));


function LogoDialog(props) {
    const {onClose, onSave, onDelete, open} = props;
    const [data, setData] = useState({DEFAULT_LOGO_INFO});
    const [dom, setDom] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [logo, setLogo] = useState(null);

    const storage = props.firebase.getstorage();
    const canvasRef = useRef();
    const classes = useHeaderStyles();
    const scroll = 'paper';

    useEffect(() => {
        if (open) {            
            setData({...props.data});
            const ref = storage.ref().child(`${StoragePath.profiles}/${props.data.path}`);
            ref.getDownloadURL().then(url => {
              var img = new Image();
              img.onload = function(){
                setLogo(img);
              };
              img.src = url;
            })
            .catch(error => {
              console.log(error);
            });

        } else {
            clearRect();
        }
    }, [open]);

    useEffect(() => {
        if (dom && open && data) {          
          drawCanvas();
        }
    }, [dom, data, logo]);


    const clearRect = () => {
        const canvas = canvasRef.current;
        if (!canvas) {
            return; 
        }
  
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
  
      const drawBackground = () => {
        const canvas = canvasRef.current;
        if (!canvas || data.preview === "") {
            return; 
        }

        const ctx = canvas.getContext("2d");   
        ctx.beginPath();
        ctx.rect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = data.preview;
        ctx.fill();
      }

      const drawLogo = () => {
        const canvas = canvasRef.current;
        if (!canvas || !logo) {
            return; 
        }
  
        const ctx = canvas.getContext("2d");
        const maxWidth = data.width>data.height?data.width:data.height;
        const scale = canvas.width/maxWidth;

        const canvasImgWidth = data.width * scale, canvasImgHeight = data.height * scale;

        const dx = (data.x - data.width/2) * scale;
        const dy = (data.y - data.height/2 ) * scale;
        let offsetX = 0, offsetY = 0;
        if (data.width>data.height) {
        offsetY = (data.width-data.height) * scale/2;
        } else {
        offsetX = (data.height-data.width) * scale/2;
        }

        ctx.drawImage(logo, dx + offsetX, dy + offsetY, canvasImgWidth, canvasImgHeight);
    }
  
    const drawLine = () => {
        const canvas = canvasRef.current;
        if (!canvas || !data.change) {
            return; 
        }
  
        const ctx = canvas.getContext("2d");
        ctx.strokeStyle = '#F477FF';
        ctx.lineWidth = 1;
  
        ctx.beginPath();
        ctx.moveTo(0, canvas.height/2);
        ctx.lineTo(canvas.width, canvas.height/2);
  
        ctx.moveTo(canvas.width/2, 0);
        ctx.lineTo(canvas.width/2, canvas.height);
        ctx.closePath();
  
        ctx.stroke();
    }
  
    const drawCanvas = () => {
        clearRect();
        drawBackground();
        drawLogo();
        drawLine();      
    }

    const handleSave = () => {
      onSave(data);
      onClose();
    };
  
    const handleClickRevert = () => {
        setData({...data, x:data.width/2, y:data.height/2});        
    }
  
    const handleCloseDialog = (event) => {
      onClose();       
    }

    const handleChange = (event) => {  
        let { name, value, checked, type} = event.target;
        value = type === "checkbox" ? checked : value;

        const _data = {...data};
        if (name === "x" || name === "y") {
            _data[name] = parseInt(value);   
        } else if (name === "change") {
            _data[name] = value;
            if (!value) {
                _data.x = _data.width/2;
                _data.y = _data.height/2;
            }
        } else {
            _data[name] = value;   
        }
        
        setData(_data);
    }

    const handleDelete = () => {        
        onDelete(data.id, data.type);
        setOpenDeleteModal(false);
        onClose();
    }

    const handleCloseRemoveDialog = () => {
        setOpenDeleteModal(false);
    }

    const renderRemoveDialog = () => {
        return (
          <Dialog onClose={handleCloseRemoveDialog} aria-labelledby="confirm-dialog-title" open={openDeleteModal}>
            <DialogTitle id="confirm-dialog-title" onClose={handleCloseRemoveDialog}>
              Confirmation
            </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                Do you want to remove current Logo?
              </Typography>          
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCloseRemoveDialog} color="primary">
                No
              </Button>
              <Button onClick={handleDelete} color="primary">Yes</Button>
            </DialogActions>
          </Dialog>
        );
    }

    let modalTitle = '';
    if (data) {
      if (data.type === 'primary_logo')
        modalTitle = "Primary Logo";
      else if (data.type === 'secondary_logo')
        modalTitle = "Secondary Logo";
      else 
        modalTitle = data.type;
    }
    return (
      <Dialog onClose={handleCloseDialog} 
          aria-labelledby="revision-dialog-title" 
          open={open}           
          fullWidth={true} 
          maxWidth={"md"} 
          className="logo-modal"
          scroll={scroll}
      >
          <DialogTitle onClose={handleCloseDialog} style={{padding: '40px 40px 10px', position: 'relative'}}>
            <IconButton style={{right: '22px'}}  aria-label="close" className={classes.closeButton} onClick={handleCloseDialog}>
              <CloseIcon/>
            </IconButton>
          </DialogTitle>
  
          <DialogContent style={{padding: 0, margin: '0 40px', borderTop: 'none'}} dividers>
              <Typography className={classes.modal_title}>{modalTitle}</Typography>   
  
              <Box className={classes.mainPane}>
                  <Box className={classes.leftPane}>  
                    <canvas 
                        style={{border: '5px solid rgb(239, 239, 239)', width:400, height:400}}
                        ref={(node) => {
                            canvasRef.current = node;
                            setDom(node!==null);
                        }}
                        width="400"
                        height="400"
                    />
                  </Box>

                  <Box className={classes.rightPane}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <InputLabel className={classes.inputLabel}>For use on</InputLabel>
                            <BootstrapInput name="name" value={data.name} onChange={handleChange} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} style={{marginTop:'20px'}}>
                        <Grid item xs={12}>
                            <InputLabel className={classes.inputLabel}>Hex Preview</InputLabel>
                            <BootstrapInput name="preview" value={data.preview} onChange={handleChange} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} style={{marginTop:'30px'}}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    color="primary"
                                    name="change"
                                    checked={data.change}
                                    onChange={handleChange}
                                />
                                }
                                label="Change Logo Center"
                            />
                        </Grid>
                    </Grid>

                    {data.change && (
                        <Box>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <InputLabel className={classes.inputLabel}>X</InputLabel>
                                    <BootstrapInput 
                                        type="number" 
                                        name="x"
                                        defaultValue={data.x}
                                        value={data.x}
                                        inputProps={{ 'min':'0', 'max': data.width }}                              
                                        onChange={handleChange}
                                    /> 
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel className={classes.inputLabel}>Y</InputLabel>
                                    <BootstrapInput 
                                        type="number" 
                                        name="y"
                                        defaultValue={data.y}
                                        value={data.y}
                                        inputProps={{ 'min':'0', 'max': data.height }}
                                        onChange={handleChange}                            
                                    />
                                </Grid>
                            </Grid>

                            <Box style={{textAlign:'right',marginTop:'10px'}}>
                                <a onClick={handleClickRevert} className={classes.revertLink}>Revert to Original</a>
                            </Box>
                        </Box>
                    )}
                  </Box>
              </Box>

              <Divider />

              <Box style={{textAlign:'right', padding:'20px 0'}}>
                <Button variant="contained" color="primary" className={classes.blueButton} onClick={handleSave}>Save</Button>
              </Box>

            <IconButton aria-label="close" className={classes.deleteButton} onClick={e=>setOpenDeleteModal(true)}>
                <DeleteOutlineIcon />
            </IconButton>

            {renderRemoveDialog()}

          </DialogContent>
      </Dialog>
    );
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(LogoDialog);
