import _ from "lodash"
import { StoragePath, CANVAS_SIZE } from "./defines"
import { getStorage, ref, getDownloadURL } from "firebase/storage";


const createSoloCanvas = () => {
  var canvas = document.createElement("canvas")
  canvas.width = CANVAS_SIZE.width
  canvas.height = CANVAS_SIZE.height
  return canvas
}

const getLogo = (storage, logo) => {
  return new Promise((resolve, reject) => {
    getDownloadURL(ref(storage, `${StoragePath.profiles}/${logo.path}`))
      .then(url => {
        var img = new Image()
        img.onload = function () {
          resolve({ info: logo, image: img })
        }
        img.src = url
        img.crossOrigin = "anonymous"
      })
      .catch(error => {
        console.log(error)
      })
  })
}

const getImage = (storage, layerId, path) => {
  return new Promise((resolve, reject) => {
    getDownloadURL(ref(storage, `${StoragePath.images}/${path}`))
      .then(url => {
        var img = new Image()
        img.onload = function () {
          resolve({ layer: layerId, image: img, name: path })
        }
        img.src = url
        img.crossOrigin = "anonymous"
      })
      .catch(error => {
        console.log(error)
      })
  })
}

const getLogos = async (storage, profile) => {
  // const logos = _.filter(profile.logos, {preferred: true});

  const requests = _.map(profile.logos, logo => getLogo(storage, logo))
  const imgs = await Promise.all(requests)
  return imgs
}

const getAllImages = async (storage, layers) => {
  let requests = []

  _.each(layers, layer => {
    console.log(
      `Layer Path:'${layer.path}', Zindex:${layer.zindex}, Visible:${layer.visible}, Class:${layer.assetClass}, Type:${layer.assetType}`
    )
    if (layer.image !== "") {
      console.log("Layer Image: ", layer.image)
      requests.push(getImage(storage, layer.id, layer.image))
    }
  })
  const imgs = await Promise.all(requests)
  return imgs
}

const getImages = async (storage, layers) => {
  let requests = []

  _.each(layers, layer => {
    console.log(
      `Layer Path:'${layer.path}', Zindex:${layer.zindex}, Visible:${layer.visible}, Class:${layer.assetClass}, Type:${layer.assetType}`
    )
    if (layer.visible && layer.image !== "") {
      console.log("Layer Image: ", layer.image)
      requests.push(getImage(storage, layer.id, layer.image))
    }
  })
  const imgs = await Promise.all(requests)
  return imgs
}

const canvasTintImage = (img, color, fill, opacity = 1) => {
  const canvas = document.createElement("canvas")
  canvas.width = img.width
  canvas.height = img.height
  const ctx = canvas.getContext("2d")

  const tintColor = !!fill ? fill : color
  if (!!tintColor) {
    ctx.fillStyle = tintColor
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    ctx.globalCompositeOperation = "destination-in"
  }

  ctx.globalAlpha = opacity / 100.0
  ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

  return canvas
}

const drawImage = (img, canvas, pos, color, opacity, effect, fill) => {
  const ctx = canvas.getContext("2d")
  //console.log(`Draw Image Path:'${path}', Color:'${color}', Pos X:${pos.x}, Y:${pos.y}, W:${pos.width}, H:${pos.height}`);
  const tintImg = canvasTintImage(img, color, fill, opacity)

  // apply the effect to the main canvas
  ctx.globalCompositeOperation = effect || "source-atop"
  ctx.drawImage(tintImg, pos.x, pos.y, pos.width, pos.height)
}

const drawLogo = (img, canvas, info, pos, opacity, effect, fill) => {
  const ctx = canvas.getContext("2d")

  const tintLogo = canvasTintImage(img, fill, fill, opacity)

  //console.log(`Draw Logo Path:'${path}', Dimension X:${offset.x}, Y:${offset.y}, W:${offset.width}, H:${offset.height}, Pos X:${pos.x}, Y:${pos.y}, W:${pos.width}, H:${pos.height}`);
  const scaleWidth = pos.width / info.width
  const scaleHeight = pos.height / info.height
  const newHeight = info.height * scaleWidth

  const centerOffsetX = (info.x - info.width / 2) * scaleWidth
  const centerOffsetY = (info.y - info.height / 2) * scaleWidth

  const left = pos.x + centerOffsetX - pos.width / 2
  const top = pos.y + centerOffsetY - newHeight / 2

  // apply the effect to the main canvas
  ctx.globalCompositeOperation = effect || "source-atop"
  ctx.drawImage(tintLogo, left, top, pos.width, newHeight)
}

const getProfileColor = (profile, path) => {
  let color = profile.colors[path]
  if (!color) return ""
  return color
}

const getNeutralColor = (neutrals, path) => {
  const neutral = _.find(neutrals, { value: path })
  if (!neutral) return ""
  return neutral.color
}

const createPreview = (canvas, layers, images, colorSets, neutrals, isFront = true) => {
  for (let i = layers.length - 1; i >= 0; i--) {
    const layer = layers[i]
    const visible = isFront ? layer.visible : layer.visibleBack
    if (!visible) continue

    const img = _.find(images, { layer: layer.id })
    if (img) {
      let color = getLayerColor(layer, colorSets, neutrals);
      if (layer.association !== "") {
        const pLayer = _.find(layers, item => item.id===layer.association);
        if (pLayer)
          color = getLayerColor(pLayer, colorSets, neutrals);
      }
      
      drawImage(img.image, canvas, layer.pos, color, layer.opacity, layer.effect, layer.fill)
    }

  }
}

const duplicateCanvas = canvas => {
  const width = canvas.width,
    height = canvas.height
  const newWidth = width * 2,
    newHeight = height

  var _canvas = document.createElement("canvas")
  _canvas.width = newWidth
  _canvas.height = newHeight
  const ctx = _canvas.getContext("2d")

  ctx.drawImage(canvas, 0, 0)
  ctx.drawImage(canvas, width, 0)
  return _canvas
}

const mergeCanvas = (canvasF, canvasB) => {
  const gap = 0
  var canvas = document.createElement("canvas")
  canvas.width = canvasF.width + gap + canvasB.width
  canvas.height = canvasF.height

  const ctx = canvas.getContext("2d")

  ctx.drawImage(canvasF, 0, 0)
  ctx.drawImage(canvasB, canvasF.width + gap, 0)
  return canvas
}

const resizeCanvas = (canvas, width) => {
  const scale = width / canvas.width
  const height = scale * canvas.height

  var _canvas = document.createElement("canvas")
  _canvas.width = width
  _canvas.height = height
  const ctx = _canvas.getContext("2d")

  ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, width, height)
  return _canvas
}

const drawOverlay = (canvas, img) => {
  console.log(`Draw Overlay`)
  const ctx = canvas.getContext("2d")
  ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
}

const cropImg = (img, area) => {
  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d")

  /* setting canvas width & height allows us to
  resize from the original image resolution */
  canvas.width = area.width * 2
  canvas.height = area.height

  ctx.drawImage(img, area.x, area.y, area.width, area.height, 0, 0, area.width, area.height)

  ctx.drawImage(img, area.x, area.y, area.width, area.height, area.width, 0, area.width, area.height)

  return new Promise(resolve => {
    var img = new Image()
    img.onload = function () {
      resolve(img)
    }
    img.crossOrigin = "anonymous"
    img.src = canvas.toDataURL()
  })
}

const resizeImg = (img, width, height, area) => {
  var canvas = document.createElement("canvas"),
    ctx = canvas.getContext("2d")

  var oc = document.createElement("canvas"),
    octx = oc.getContext("2d")

  oc.width = width
  oc.height = height

  octx.drawImage(img, 0, 0, oc.width, oc.height)

  canvas.width = area.width * 2
  canvas.height = area.height

  ctx.drawImage(oc, area.x, area.y, area.width, area.height, 0, 0, area.width, area.height)

  ctx.drawImage(oc, area.x, area.y, area.width, area.height, area.width, 0, area.width, area.height)

  return new Promise(resolve => {
    var img = new Image()
    img.onload = function () {
      resolve(img)
    }
    img.crossOrigin = "anonymous"
    img.src = canvas.toDataURL()
  })
}

const getLayerColor = (layer, colorSets, neutrals) => {
  let color = ""
  if (layer.assetType !== "graphic") return color
  if (layer.swatch === "property") {
    const path = layer.custom1
    color = '';
  } else if (layer.swatch === "neutrals") {
    const path = layer.custom1
    color = getNeutralColor(neutrals, path)
  } else if (layer.swatch === "colorsets") {
    const colorSet = _.find(colorSets, { id: layer.custom1 })
    const colorSetItem = _.find(colorSet.items, { id: layer.custom2 });
    if (colorSetItem)
      color = colorSetItem.value;
  }
  return color
}

export {
  getProfileColor,
  getImages,
  getAllImages,
  getLogos,
  createSoloCanvas,
  createPreview,
  resizeCanvas,
  drawOverlay,
  cropImg,
  resizeImg,
}
