import {collections} from './defines';
import _  from "lodash";
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  writeBatch
} from "firebase/firestore";

const getLayer = (db, name, callback) => {
  const q = query(collection(db, collections.LAYERS), where("name", "==", name));

  return getDocs(q)
  .then((querySnapshot) => {
    let _item = {exists: false, qty: 1, sku: name, name: '', format: '', size: ''};

    querySnapshot.forEach((doc) => {
      const data = doc.data(); 
      if (doc.exists) {
        const data = doc.data();              
        _item.name = data.name;
        _item.path = data.path;
        _item.pos = data.pos;
        _item.rotate = data.rotate;
        _item.opacity = data.opacity;
        _item.static = data.static;
        _item.visible = data.visible;
        _item.visibleBack = data.visibleBack;            
        _item.zindex = data.zindex ? data.zindex : 1;
        _item.data = data;
        _item.expanded = false;
      }
    });
    callback(_item);
  })
  .catch( error => {
    console.log('getDesign: ', error);
  });
}

const getLayers = (db, templateId, callback) => {
  const q = query(collection(db, collections.LAYERS), where("template", "==", templateId), orderBy('zindex', 'desc'));

  return getDocs(q)
  .then((querySnapshot) => {
    let items = [];
    querySnapshot.forEach((doc) => {
      items.push({...doc.data(), id:doc.id});
    });
    callback(items);
  })
}

const addLayer = (db, item, callback) => {
  addDoc(collection(db, collections.LAYERS), item)  
    .then((docRef) => {
      console.log("Document written with ID: ", docRef.id);
      callback({success: true, id: docRef.id});
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
      callback({success: false});   
    });

}

const createLayer = (db, item) => {
  const newItem = { ...item };
  const currentTime = Date();
  newItem.created = currentTime;

  addDoc(collection(db, collections.LAYERS), newItem)  
    .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
    })
    .catch((error) => {
        console.error("Error adding document: ", error);        
    });
}

const updateLayer = (db, item) => {
  const docRef = doc(db, collections.LAYERS, item.id);
  updateDoc(docRef, {
    name: item.name,
    path: item.path,
    visible: item.visible,
    visibleBack: item.visibleBack,
    zindex: item.zindex ? parseInt(item.zindex) : 1
    })
    .then(() => {
      console.log("Document successfully updated!");
    })
    .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    });
}

const deleteLayer = (db, item) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, collections.LAYERS, item.id);
    deleteDoc(docRef)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.error("Error deleteLayer: ", error);
        resolve();         
      });
  });
}

const deleteDesignLayers = (db, item) => {
  var layers = db.collection(collections.LAYERS).where('template', '==', item.id);
  layers.get().then(function(querySnapshot) {
    querySnapshot.forEach(function(doc) {
      doc.ref.delete();
    });
  });

}

const updateLayers = async (db, layers, designId) => {

  const batch = writeBatch(db);
  // Update Layers
  layers.forEach((layer, index) => {   
    const docRef = doc(db, collections.LAYERS, layer.id);
    if (layer.removed) {
      deleteDoc(docRef); 
    } else {
      batch.set(docRef, {
        ...layer,          
        template: designId 
      }, { merge: true });
    }  

  });

  await batch.commit();
}

export { getLayer, getLayers, createLayer, updateLayer, updateLayers, deleteLayer, addLayer, deleteDesignLayers };