const GCP_PROJECT_ID = process.env.REACT_APP_PROJECT_ID;

const collections = {
    PROFILES: "Profiles",
    DESIGNS: "Designs",
    LAYERS: "Layers",
    COLLECTIONS: "Collections",  
    COLORSETS: "ColorSets",
    PATTERNS: "Patterns",
    NEUTRALS: "Neutrals",
    PRODUCTS: "Products"
};

const CANVAS_SIZE = {
    width: 1725,
    height: 4950
};

const StoragePath = {
    previews: "previews",
    images: "images",
    profiles: "logos",
    overaly: "overlays",
    collections: "collections",
    patterns: "patterns",
    zip: "zip",
    products: "products"
}

const DEFAULT_PROFILE = {
    name: "",
    sku: "",
    collection: "",
    primary_name: "",
    secondary_name: "",

    colors: {
        primary_color: "",
        secondary_color: "",
        third_color: "",
        fourth_color: "",
        fifth_color: "",    
        primary_tonal: "",
        secondary_tonal: "",
        third_tonal: "",
        fourth_tonal: "",
        fifth_tonal: "",    
        primary_tonal2: "",
        secondary_tonal2: "",
        third_tonal2: "",
        fourth_tonal2: "",
        fifth_tonal2: "",  
        
        primary_tonal_light: "",
        secondary_tonal_light: "",
        third_tonal_light: "",
        fourth_tonal_light: "",
        fifth_tonal_light: "",    
        primary_tonal2_light: "",
        secondary_tonal2_light: "",
        third_tonal2_light: "",
        fourth_tonal2_light: "",
        fifth_tonal2_light: "",        
    },
    logos:[]
};

const DEFAULT_DESIGN = {
    id: "",
    name: "",
    sku: "",
    status: "normal",
    format: "Crew",
    colorSets: [],
    product: ""
};

const CanvasDimensions = [
    {format: "Crew", width:1725, height: 4950},
    {format: "Low", width:1725, height: 3000}
];

const DEFAULT_LAYER_POS = {
    x: 0,
    y: 0,
    width: 0,  
    height: 0,
    _width: 0,
    _height: 0
};

const DEFAULT_LAYER = {
    id: "",
    name: "",    
    assetType: "graphic",
    assetClass: "shape",
    swatch: "property",
    pos: {...DEFAULT_LAYER_POS},
    rotate: 0,
    opacity: 100,
    image: '',
    custom1: '',
    custom2: '',
    custom3: '',
    effect: 'normal',
    fill: '',
    zindex: 10,
    static: false,
    visible: true,
    visibleBack: false,
    removed: false
};

const DEFAULT_COLLECTION = {
    id: "",
    name: "",
    sku: "", 
    tags: []
};

const DEFAULT_COLORSET = {
    id: "",
    name: "",
    items: []
};

const DEFAULT_PATTERN = {
    id: "",
    name: "",
    items: []
};

const DEFAULT_PROJECT = {
    id: "",
    name: "",
    sku: "",
    format: "Crew",
    status: "normal",
    design: "",
    collection: "",
    mockup: false,
    total: 0,
    approved: 0
};

const DEFAULT_PROJECT_PROFILE = {
    project: "",
    profile: "",    
    mockup: false,
    status: "pending",
    primary_logo: "",
    secondary_logo: "",
    layers: [],    
}

const LAYER_CLASS_OPTIONS = [
    {name: 'Textbox', value: 'textbox', type: 'text'},
    {name: 'Shape', value: 'shape', type: 'graphic'},
    {name: 'Pattern', value: 'pattern', type: 'graphic'},
    {name: 'Logo', value: 'logo', type: 'object'},    
];

const LAYER_SWATCH_OPTIONS = [
    {name: 'Property', label: 'Main Colors', value: 'property', type: 'graphic'},
    {name: 'Neutrals', label: 'Neutrals', value: 'neutrals', type: 'graphic'},
    {name: 'Color Sets', label: 'Color Sets', value: 'colorsets', type: 'graphic'},
    {name: 'Patterns', label: 'Patterns', value: 'patterns', type: 'graphic'},    
];

const LAYER_OPTIONS = [
    {name: 'Primary Logo', value: 'primary_logo', type: 'object', swatch:''},
    {name: 'Secondary Logo', value: 'secondary_logo', type: 'object', swatch:''},
    
    {name: 'Primary Color', value: 'primary_color', type: 'graphic', swatch:'property'},
    {name: 'Secondary Color', value: 'secondary_color', type: 'graphic', swatch:'property'},
    {name: 'Third Color', value: 'third_color', type: 'graphic', swatch:'property'},
    {name: 'Fourth Color', value: 'fourth_color', type: 'graphic', swatch:'property'},
    {name: 'Fifth Color', value: 'fifth_color', type: 'graphic', swatch:'property'},

    {name: 'Primary Tonal 1 (Dark)', value: 'primary_tonal', type: 'graphic', swatch:'property'},
    {name: 'Secondary Tonal 1 (Dark)', value: 'secondary_tonal', type: 'graphic', swatch:'property'},
    {name: 'Third Tonal 1 (Dark)', value: 'third_tonal', type: 'graphic', swatch:'property'},
    {name: 'Fourth Tonal 1 (Dark)', value: 'fourth_tonal', type: 'graphic', swatch:'property'},
    {name: 'Fifth Tonal 1 (Dark)', value: 'fifth_tonal', type: 'graphic', swatch:'property'},
    {name: 'Primary Tonal 2 (Dark)', value: 'primary_tonal2', type: 'graphic', swatch:'property'},
    {name: 'Secondary Tonal 2 (Dark)', value: 'secondary_tonal2', type: 'graphic', swatch:'property'},
    {name: 'Third Tonal 2 (Dark)', value: 'third_tonal2', type: 'graphic', swatch:'property'},
    {name: 'Fourth Tonal 2 (Dark)', value: 'fourth_tonal2', type: 'graphic', swatch:'property'},
    {name: 'Fifth Tonal 2 (Dark)', value: 'fifth_tonal2', type: 'graphic', swatch:'property'},

    {name: 'Primary Tonal 1 (Light)', value: 'primary_tonal_light', type: 'graphic', swatch:'property'},
    {name: 'Secondary Tonal 1 (Light)', value: 'secondary_tonal_light', type: 'graphic', swatch:'property'},
    {name: 'Third Tonal 1 (Light)', value: 'third_tonal_light', type: 'graphic', swatch:'property'},
    {name: 'Fourth Tonal 1 (Light)', value: 'fourth_tonal_light', type: 'graphic', swatch:'property'},
    {name: 'Fifth Tonal 1 (Light)', value: 'fifth_tonal_light', type: 'graphic', swatch:'property'},
    {name: 'Primary Tonal 2 (Light)', value: 'primary_tonal2_light', type: 'graphic', swatch:'property'},
    {name: 'Secondary Tonal 2 (Light)', value: 'secondary_tonal2_light', type: 'graphic', swatch:'property'},
    {name: 'Third Tonal 2 (Light)', value: 'third_tonal2_light', type: 'graphic', swatch:'property'},
    {name: 'Fourth Tonal 2 (Light)', value: 'fourth_tonal2_light', type: 'graphic', swatch:'property'},
    {name: 'Fifth Tonal 2 (Light)', value: 'fifth_tonal2_light', type: 'graphic', swatch:'property'},


    {name: 'Primary Neutral', value: 'primary_neutral_color', type: 'graphic', swatch:'neutrals'},
    {name: 'Secondary Neutral', value: 'secondary_neutral_color', type: 'graphic', swatch:'neutrals'},
    {name: 'Primary Neutral Tonal 1', value: 'primary_neutral_tonal', type: 'graphic', swatch:'neutrals'},
    {name: 'Secondary Neutral Tonal 1', value: 'secondary_neutral_tonal', type: 'graphic', swatch:'neutrals'},
    {name: 'Primary Neutral Tonal 2', value: 'primary_neutral_tonal2', type: 'graphic', swatch:'neutrals'},
    {name: 'Secondary Neutral Tonal 2', value: 'secondary_neutral_tonal2', type: 'graphic', swatch:'neutrals'},    
];

const NEUTRAL_OPTIONS = [
    {name: 'Primary Neutral', value: 'primary_neutral_color', type: 'graphic', swatch:'neutrals'},
    {name: 'Secondary Neutral', value: 'secondary_neutral_color', type: 'graphic', swatch:'neutrals'},
    {name: 'Primary Neutral Tonal 1', value: 'primary_neutral_tonal', type: 'graphic', swatch:'neutrals'},
    {name: 'Secondary Neutral Tonal 1', value: 'secondary_neutral_tonal', type: 'graphic', swatch:'neutrals'},
    {name: 'Primary Neutral Tonal 2', value: 'primary_neutral_tonal2', type: 'graphic', swatch:'neutrals'},
    {name: 'Secondary Neutral Tonal 2', value: 'secondary_neutral_tonal2', type: 'graphic', swatch:'neutrals'},  
];

const DEFAULT_LOGO_DIMENSION = {width: 600, height: 600};

const DEFAULT_LOGO_INFO = {
    id: "",
    path: "",
    name: "",
    preview: "",
    width: 600,
    height: 600,
    x: 300,
    y: 300,
    preferred: false,    
    type: "",
    change: false
};

const DEFAULT_PRODUCT = {
    colorSet: "",
    design: "",
    profile: "",
    name: "",
    sku: "",
    format: "",
    vendor: "Rock 'Em Socks",
    template: "creator",
    shopifyCreated: false,
    shopifyProduct: null
};

const projectSettings = {
    'creator-studio-cms': {
        defaultBucket:'creator-studio-cms.appspot.com',
        environmentName: 'live'
    },
    'creator-studio-stage': {
        defaultBucket:'creator-studio-stage.firebaseapp.com',
        environmentName: 'dev'
    }
}

const CropSettings = {
    'Crew': {
        canvas: {
            width: 1725,
            height: 4950
        },
        frontArea: {
            x: 225,
            y: 75,
            width: 1275,
            height: 4560
        },
        backArea: {
            x: 225,
            y: 75,
            width: 1275,
            height: 2662
        }
    },
    'Low': {
        canvas: {
            width: 1725,
            height: 3000
        },
        frontArea: {
            x: 225,
            y: 75,
            width: 1275,
            height: 2625
        },
        backArea: {
            x: 225,
            y: 75,
            width: 1275,
            height: 2625
        }
    },
};

export {
    collections, 
    StoragePath,
    CANVAS_SIZE,
    CanvasDimensions, 
    DEFAULT_DESIGN,
    DEFAULT_PROFILE,
    DEFAULT_PROJECT,
    DEFAULT_LAYER,
    DEFAULT_LAYER_POS,
    DEFAULT_COLLECTION,
    LAYER_OPTIONS,
    NEUTRAL_OPTIONS,
    LAYER_CLASS_OPTIONS,
    LAYER_SWATCH_OPTIONS,
    DEFAULT_LOGO_DIMENSION,
    DEFAULT_LOGO_INFO,
    DEFAULT_PROJECT_PROFILE,
    DEFAULT_PRODUCT,
    DEFAULT_COLORSET,
    DEFAULT_PATTERN,
    CropSettings
};
