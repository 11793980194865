import React from "react"
import Navigation from '../Navigation';

export default function Layout(props) {
  
  return (
    <section>
        <Navigation page={props.page} />
        <div className="Content">
            <div className="Container">{props.children}</div>            
        </div>
    </section>
  )
}