import {collections} from './defines';
import _  from "lodash";

const getCollection = (db, name, callback) => {
  db
    .collection(collections.COLLECTIONS)
    .where("name", "==", name)
    .get()
    .then(snapshot => {
      let _item = {exists: false, qty: 1, sku: name, name: '', format: '', size: ''};
      if (snapshot.docs.length > 0) {
        snapshot.docs.forEach(doc => {
          if (doc.exists) {
            const data = doc.data();              
            _item.name = data.name;
            _item.data = data;
            _item.sku = data.sku;
            _item.logo = data.logo;
            _item.tags = data.tags;
          }      
        });        
      }
      
      callback(_item);
    })
    .catch( error => {
      console.log('getCollection: ', error);
    });
}

const getCollections = (db, callback) => {
  db
  .collection(collections.COLLECTIONS)  
  .orderBy("name")
  .onSnapshot((snapshot) => {

    if (snapshot.docs.length > 0) {
      const items = _.map(snapshot.docs, item => {
        let data = item.data();
        return {
          id: item.id,
          name: data.name,
          sku: data.sku,
          logo: data.logo,
          tags: data.tags
        };
      });
  
      callback(items);       
    }
  },
  error => {
    console.log(error);
    let items = [];
    callback(items);  
  }
  );
}

const createCollection = (db, item) => {
  const currentTime = Date();

  db.collection(collections.COLLECTIONS).add({
    created: currentTime,
    name: item.name,
    sku: item.sku,
    logo: item.logo,
    tags: item.tags
  });
}

const updateCollection = (db, item) => {
  db
    .collection(collections.COLLECTIONS)
    .doc(item.id)
    .update({
      name: item.name,
      sku: item.sku,
      logo: item.logo,
      tags: item.tags
    });
}

const deleteCollection = (db, item) => {
  db.collection(collections.COLLECTIONS).doc(item.id).delete();  
}

export { getCollection, getCollections, createCollection, updateCollection, deleteCollection };