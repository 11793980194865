import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles  } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InputBase from '@material-ui/core/InputBase';
import { withAuthorization } from '../Session';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import _  from "lodash";
import { DEFAULT_PRODUCT } from '../Utils/defines';
import { createProduct, getProduct, updateProduct } from '../Utils/products';


const BootstrapInput = withStyles((theme) => ({
  root: {
    width: '100%',
    'label + &': {
      marginTop: '5px',
      width: '100%'
    },
    'label': {
      fontSize: '16px',
      fontWeight: '500',
      color: '#000000'
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 10px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  }
}))(InputBase);

const useHeaderStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    color: '#000000',
  },
  inputRoot: {
    width: '100%',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px',
    color: '#000000',
    background: 'rgba(0,0,0,0.04)',
    padding: '11px 15px',
    borderRadius: '4px'
  },
  inputLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000'
  },
  publishButton: {
    background: '#14b67a',
    padding: '8px 25px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial',
    marginLeft: '20px'
  },
  inputInlineLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    marginTop: '15px',
    marginRight: '25px'
  },
  inputInlineSmallLabel: {
    fontSize: '13px',
    fontWeight: '400',
    color: '#000000',
    fontStyle: 'italic',
    marginTop: '5px'
  },
}));

function ProductModal(props) {
  const {handleClose, open, design, profiles} = props;  
  const [product, setProduct] = useState({...DEFAULT_PRODUCT});
  const [colorSets, setColorSets] = useState([]);
  const db = props.firebase.getdb();

  useEffect(async () => {
    if (open) {
      const _colorSets = _.filter(props.colorSets, item => {
        return design.colorSets.includes(item.id);
      });
      setColorSets([..._colorSets]);
      
      let _product = {...DEFAULT_PRODUCT};

      if (props.design.product !== "") {
        const oldProduct = await getProduct(db, props.design.product);
        _product.colorSet = oldProduct.colorSet;
      } 
      setProduct({..._product, design:props.design.id, format:props.design.format, name:props.design.name, sku:props.design.sku});
      
    } 

  }, [open]);


  const handleCloseDialog = (event) => {
    handleClose(); 
  }

  const handlePublish = async ()=> {
    let productId = props.design.product;
    if (productId === "") {
      productId = await createProduct(db, product);
    } else {
      await updateProduct(db, productId, product);
    }

   if (props.handlePublish)
    props.handlePublish(productId)

   handleClose();
  }

  const checkAvailability = () => {
    return product.design !== "" && product.colorSet !== "" && product.name !== "" && product.sku !== "";
  }

  const classes = useHeaderStyles();
  const scroll = 'paper';
  const availability = checkAvailability();

  return (
    <Dialog onClose={handleCloseDialog} 
        aria-labelledby="revision-dialog-title" 
        open={open} 
        fullWidth={true} 
        maxWidth={"md"} 
        className="design-modal"
        scroll={scroll}
    >
        <DialogTitle onClose={handleCloseDialog} style={{padding: '30px 40px 20px', position: 'relative'}}>
            <Typography variant="h1">Create Product</Typography>   
            <IconButton style={{right: '22px'}} aria-label="close" className={classes.closeButton} onClick={handleCloseDialog}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        
        <DialogContent style={{padding: '30px 40px'}} dividers>
            <Box>
                <Grid container spacing={1}>  
                    <Grid item xs={5}>
                        <InputLabel className={classes.inputInlineLabel}>Color Set</InputLabel>
                        <Select                      
                            style={{width: '100%'}}
                            input={<BootstrapInput />}
                            value={product.colorSet}
                            onChange={(e) => setProduct({...product, colorSet: e.target.value})} 
                        >     
                            <MenuItem value="">Choose Color Set</MenuItem>
                            {colorSets.map(function(colorSet){
                                return <MenuItem value={colorSet.id}>{colorSet.name}</MenuItem>
                            })} 
                        </Select > 
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={5}>
                        <InputLabel className={classes.inputInlineLabel}>Profile*</InputLabel>
                        <Select                      
                            style={{width: '100%'}}
                            input={<BootstrapInput />}
                            value={product.profile}
                            onChange={(e) => setProduct({...product, profile: e.target.value})} 
                        >     
                            <MenuItem value="">Choose Profile</MenuItem>
                            {profiles.map(function(profile){
                                return <MenuItem value={profile.id}>{profile.name}</MenuItem>
                            })} 
                        </Select >                       
                        <InputLabel className={classes.inputInlineSmallLabel}>*optinal</InputLabel>
                    </Grid>

                    <Grid item xs={5}>
                        <InputLabel className={classes.inputInlineLabel}>Product Name</InputLabel>
                        <BootstrapInput 
                            label="Name"
                            type="text"                                         
                            value={product.name}
                            placeholder=""
                            onChange={(e) => setProduct({...product, name: e.target.value})}
                        />
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={5}>
                        <InputLabel className={classes.inputInlineLabel}>Product SKU</InputLabel>
                        <BootstrapInput 
                            label="SKU"
                            type="text"                                         
                            value={product.sku}
                            placeholder=""
                            onChange={(e) => setProduct({...product, sku: e.target.value})}
                        />                        
                    </Grid>

                </Grid>
            </Box>

        </DialogContent>
        <DialogActions  style={{padding: '20px 40px', justifyContent: 'flex-end'}}>
            <Button 
                variant="contained" 
                color="primary" 
                className={classes.publishButton} 
                onClick={handlePublish} 
                disabled={!availability}
            >Publish to Shopify</Button>
        </DialogActions>
    </Dialog>
  );
}


const condition = authUser => !!authUser;
export default withAuthorization(condition)(ProductModal);
