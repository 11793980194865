import {collections} from './defines';
import _  from "lodash";
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot
} from "firebase/firestore";

const getDesign = (db, name, callback) => {
  const q = query(collection(db, collections.DESIGNS), where("name", "==", name));

  return getDocs(q)
  .then((querySnapshot) => {
    let _item = {exists: false, qty: 1, sku: '', name: '', format: '', size: ''};

    querySnapshot.forEach((doc) => {
      const data = doc.data(); 
      _item.name = data.name;
      _item.sku = data.sku;
      _item.colorSets = data.colorSets;
      _item.product = data.product;      
    });
    callback(_item);
  })
  .catch( error => {
    console.log('getDesign: ', error);
  });

}

const getDesigns = (db, status, callback) => {
  const q = query(collection(db, collections.DESIGNS), where("status", "==", status), orderBy('name'));

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    console.log('Designs Changed...');
    let items = [];
    querySnapshot.forEach((doc) => {
      items.push({...doc.data(), id:doc.id});
    });
    callback(items);
  });
}

const createDesign = (db, item, callback) => {
  const newItem = { ...item };
  const currentTime = Date();
  newItem.created = currentTime;
  newItem.mockup = false;

  addDoc(collection(db, collections.DESIGNS), newItem)  
    .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        if (callback) {
          callback(true, docRef.id);
        }
    })
    .catch((error) => {
        console.error("Error adding document: ", error);
        if (callback) {
          callback(false);
        }
    });
}

const updateDesignItem = (db, designId, item) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, collections.DESIGNS, designId);
    updateDoc(docRef, {
        ...item
      })
      .then(() => {
        console.log("Document successfully updated!");
        resolve();
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        resolve();         
      });
  });
}

const updateDesign = (db, item, callback) => {
  const docRef = doc(db, collections.DESIGNS, item.id);
  updateDoc(docRef, {
      name: item.name,
      status: item.status,
      sku: item.sku,
      format: item.format,
      colorSets: item.colorSets,
      product: item.product.trim(),
      mockup: false,
      updated:  Date()
    })
    .then(() => {
      console.log("Document successfully updated!");
      if (callback)
        callback(true, item.id);
    })
    .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        if (callback)
          callback(false);        
    });
}

const removeDesign = (db, item) => {
  deleteDoc(doc(db, collections.DESIGNS, item.id));
}

const deleteDesign = (db, item) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, collections.DESIGNS, item.id);
    updateDoc(docRef, {
        status: 'deleted'
      })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        resolve();         
      });
  }); 
}

export { getDesign, getDesigns, createDesign, updateDesign, updateDesignItem, deleteDesign, removeDesign };