
import React, { Component } from 'react';
import Box from '@material-ui/core/Box';

function isImageLoaded(imgElement) {
    return (typeof imgElement.naturalWidth != 'undefined' &&
        imgElement.naturalWidth != 0);
}

function getBuffer(imgElement) {
    var buffer = document.createElement('canvas');
    if (typeof imgElement.naturalWidth != 'undefined' && typeof imgElement.naturalHeight != 'undefined') {
        buffer.width = imgElement.naturalWidth;
        buffer.height = imgElement.naturalHeight;
    }
    else {
        buffer.width = imgElement.width;
        buffer.height = imgElement.height;
    }
    return buffer;
}

var tintCache = {};
function tintData(imgElement, color, options) {
    if (options === void 0) { options = {}; }
    if (options.cache && tintCache[imgElement.src]) {
        return Promise.resolve(tintCache[imgElement.src]);
    }
    if (!isImageLoaded(imgElement)) {
        return new Promise(function (resolve, reject) {
            imgElement.onload = function () { return resolve(tintData(imgElement, color, options)); };
            imgElement.onerror = reject;
        });
    }
    var buffer = getBuffer(imgElement);
    var context = buffer.getContext('2d');
    var width = buffer.width;
    var height = buffer.height;
    // If we can't create a context, return the source image's src attribute.
    if (!buffer || !context) {
        return Promise.resolve(imgElement.src);
    }
    // Clear the context.
    context.clearRect(0, 0, width, height);
    // Set the fill color.
    context.globalCompositeOperation = 'source-over';
    context.fillStyle = color;
    // Fill the context.
    context.fillRect(0, 0, width, height);
    // Draw image the specified blending mode.
    context.globalCompositeOperation = 'destination-in';
    context.drawImage(imgElement, 0, 0);
    var dataUrl = imgElement.src;
    try {
        dataUrl = buffer.toDataURL('image/png');
        if (options.cache) {
            tintCache[imgElement.src] = dataUrl;
        }
    }
    catch (e) {
    }
    return Promise.resolve(dataUrl);
}

function isColorful(src) {
    return src.indexOf('[colorful]') !== -1;
}

export class ImageTint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      src: props.src
    };
    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;

    this.applyTint(this.props.src, this.props.color);
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentWillReceiveProps(newProps) {
    if (newProps.src !== this.props.src) {
      this.applyTint(newProps.src, newProps.color);
    }
  }

  applyTint(src, color) {
    if (!isColorful(src) && this.refs.img) {
      const imgElement = this.refs.img;

      tintData(imgElement, color, { cache: Boolean(this.props.cache) })
        .then((src) => this._mounted && this.setState({ src }));
    }
  }
  
  render() {
    const opacity = this.state.src.includes('base64,')?1:0;
    return (
        <Box style={{width:'100%',height:'100%'}}>
            <img
                src={this.state.src}
                alt={this.props.alt}
                ref="img"
                crossOrigin="anonymous"
                style={{opacity: `${opacity}`, width:'100%', height:'100%'}}
            />
        </Box>

    );
  }
}

export default ImageTint;
