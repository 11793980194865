import {collections, DEFAULT_PROFILE} from './defines';
import _  from "lodash";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";

const getProfile = (db, name, callback) => {
  db
    .collection(collections.COLLECTIONS)
    .where("name", "==", name)
    .get()
    .then(snapshot => {
      let _item = {exists: false, qty: 1, sku: name, name: '', format: '', size: ''};
      if (snapshot.docs.length > 0) {
        snapshot.docs.forEach(doc => {
          if (doc.exists) {
            const data = doc.data();              
            _item.name = data.name;
            _item.sku = data.sku;
            _item.collection = data.collection;
            _item.data = data;
          }      
        });        
      }
      
      callback(_item);
    })
    .catch( error => {
      console.log('getProfile: ', error);
    });
}

const getProfiles = (db, callback) => {
  const q = query(collection(db, collections.PROFILES), orderBy('name'));

  return getDocs(q)
  .then((querySnapshot) => {
    let items = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      items.push({...DEFAULT_PROFILE, ...doc.data(), id:doc.id});
    });
    callback(items);
  })
}


const createProfile = (db, item) => {
  const newItem = { ...item };
  const currentTime = Date();
  newItem.created = currentTime;

  db.collection(collections.PROFILES).add(newItem);
}

const updateProfile = (db, item) => {

  db
    .collection(collections.PROFILES)
    .doc(item.id)
    .update({...item});
}

const deleteProfile = (db, item) => {
  db.collection(collections.PROFILES).doc(item.id).delete();  
}

export { getProfile, getProfiles, createProfile, updateProfile, deleteProfile };